/* eslint-disable react/no-multi-comp */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	CircularProgress,
	Box,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedNumber } from 'react-intl';
import budgetActions from 'src/redux/actions/budgetActions';
import Label from 'src/components/Label';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
	root: {
		padding: 0
	},
	addIcon: {
		marginRight: theme.spacing(1)
	},
	done: {
		textDecoration: 'line-through',
		color: theme.palette.text.secondary
	},
	cardContent: {
		display: 'flex',
		justifyContent: 'center',
		padding: 0
	},
	noPayments: {
		marginTop: theme.spacing(5)		
	}
}));

const { getPaymentTracker } = budgetActions;

function UpcomingPayments({ className, ...rest }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const budget = useSelector(state => state.budget);

	useEffect(() => {
		dispatch(getPaymentTracker());
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	let upcomingPayments = [];
	if (budget.paymentTracker && budget.paymentTracker.tracker) {
		upcomingPayments = budget.paymentTracker.tracker
			.flatMap(month => {
				return month.payments.filter(p => p.paid === false)
			}).sort((a, b) => {
				return new Date(a.date) - new Date(b.date);

			});

	}


	const getLabelData = (payment) => {

		let { paid: status } = payment;

		let color, text;
		switch (status) {
			case true:
				color = 'success';
				text = 'Paid';
				break;

			case false:
				color = 'warning';
				text = 'Pending';
				break;

			default:
				color = 'warning';
				text = 'Unknown';
		}

		if (!payment.paid && new Date(payment.date) < new Date()) {
			color = 'error';
			text = 'overdue';
		}

		return { color, text }


	}


	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<CardHeader
				title="Upcoming Payments"
			/>
			<Divider />
			<CardContent className={classes.cardContent}>
				{(!budget.paymentTracker || !budget.paymentTracker.tracker) && <CircularProgress></CircularProgress>}

				{(budget.paymentTracker.tracker && upcomingPayments.length === 0) &&
					<Box className={classes.noPayments}>
						<Typography>No upcoming payments.</Typography>
					</Box>}
				{upcomingPayments.length > 0 &&
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell>Date</TableCell>
								<TableCell>Item</TableCell>
								{!isMobile && <TableCell>Status</TableCell>}
								<TableCell>Total</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{upcomingPayments.map((payment, i) => (
								<TableRow
									hover
									key={i}
								>
									<TableCell>{moment(payment.date).format('MM/DD/YYYY')}</TableCell>
									<TableCell>{payment.itemName}</TableCell>
									{!isMobile && <TableCell><Label color={getLabelData(payment).color}>{getLabelData(payment).text}</Label></TableCell>}
									<TableCell><FormattedNumber style={`currency`} currency="USD" value={payment.amount} /></TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				}
			</CardContent>
		</Card>
	);
}

UpcomingPayments.propTypes = {
	className: PropTypes.string
};

export default UpcomingPayments;
