// CheckoutForm.js
import React from 'react';
import { injectStripe } from 'react-stripe-elements';
import clsx from 'clsx';

import { CardElement } from 'react-stripe-elements';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green, red } from '@material-ui/core/colors';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonError: {
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '8%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

function PaymentForm(props) {

    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [cardElement, setCardElement] = React.useState({});
    const session = useSelector(state => state.session);

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
        [classes.buttonError]: !success && submitted
    });

    const handleSubmit = async (ev) => {
        // We don't want to let default form submission happen here, which would refresh the page.
        ev.preventDefault();

        setSubmitted(true);
        setLoading(true);

        const res = await fetch(`${process.env.REACT_APP_API_HOST}/payments/intent`, {
            headers: {
                Authorization: `Bearer ${session.token}`,
                'Content-Type': 'application/json',
            },
        });
        const { clientSecret } = await res.json();


        const { paymentIntent, error } = await props.stripe.confirmCardPayment(
            clientSecret, {
            payment_method: {
                card: cardElement
            }
        }
        );

        setLoading(false);

        if (error) {
            // Handle payment error
            setSuccess(false);
        } else if (paymentIntent && paymentIntent.status === "succeeded") {
            // Handle payment success
            setSuccess(true);
        }
    }

    const handleReady = element => {
        setCardElement(element);
    };

    const createOptions = (fontSize, padding) => {
        return {
            style: {
                base: {
                    fontSize,
                    color: '#424770',
                    letterSpacing: '0.025em',
                    fontFamily: 'Source Code Pro, monospace',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                    padding,
                },
                invalid: {
                    color: '#9e2146',
                },
            },
        };
    };

    return (
        <form onSubmit={handleSubmit}>
            <label>
                <CardElement
                    style={{
                        base: {
                            fontSize: '18px',
                            color: '#000',
                            fontWeight: 500,
                            // fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                            fontSmoothing: 'antialiased',

                            ':-webkit-autofill': {
                                color: '#fce883',
                            },
                            '::placeholder': {
                                color: '#9E9E9E',
                            }
                        }
                    }}
                    onReady={handleReady} />
            </label>
            <br />

            <div className={classes.wrapper}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    className={buttonClassname}
                    type="submit"
                    {...createOptions(12)}
                >Pay $10</Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
        </form>
    );

}

export default injectStripe(PaymentForm);