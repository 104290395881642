import React, { Component } from "react";
// import ImageCellView from './imageCell';
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { TextField } from "@material-ui/core";

const DateCell = (data) => <p>{data.toLocaleString()}</p>;
const LinkCell = (link, href) => <a href={href ? href : "#"}>{link}</a>;

function EditableNumberCell({
  value,
  onChange,
  columnsKey,
  index,
  margin,
  inputClassName,
}) {
  const [localValue, setLocalValue] = React.useState(value);

  const handleChange = (_, value) => {
    // The CurrencyTextField component triggers an onChange event onBlur.
    // That will retrigger our update line item calls which we don't want.
    if (value === localValue) return;
    setLocalValue(value);
    if (onChange) {
      onChange(value, columnsKey, index);
    }
  };

  return (
    <div className="isoEditData">
      <div className="">
        <CurrencyTextField
          value={value}
          margin={margin}
          minimumValue="0"
          onChange={handleChange}
          InputProps={{
            classes: {
              input: inputClassName,
            },
            onWheel: (event) => event.currentTarget.blur(),
          }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        />
      </div>
    </div>
  );
}

class EditableTextCell extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      value: this.props.value,
      editable: false,
    };
  }
  handleChange(event) {
    if (this.props.onChange) {
      this.props.onChange(
        event.target.value,
        this.props.columnsKey,
        this.props.index
      );
    }
  }

  render() {
    const { value } = this.state;
    return (
      <TextField
        key={this.props.useKey}
        defaultValue={value}
        fullWidth={true}
        margin={this.props.margin}
        InputProps={{
          classes: {
            input: this.props.inputClassName,
          },
        }}
        onChange={(e) => this.handleChange(e)}
      />
    );
  }
}

class DeleteCell extends Component {
  render() {
    return (
      // <Popconfirm
      //     title="Sure to delete?"
      //     okText="DELETE"
      //     cancelText="No"
      //     onConfirm={() => onDeleteCell(index)}
      // >
      //     <a>Delete</a>
      // </Popconfirm>
      <div></div>
    );
  }
}

export { DateCell, LinkCell, EditableNumberCell, DeleteCell, EditableTextCell };
