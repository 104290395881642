import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import budgetActions from 'src/redux/actions/budgetActions'
import BudgetComponent from 'src/components/Budgets';
import { Container, Typography, useTheme, Hidden } from '@material-ui/core';
import Header from './Header';
import Overview from './Overview';
import { useParams, useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import Joyride, { EVENTS } from 'react-joyride';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3)
	},
	grid: {
		marginTop: theme.spacing(2)
	}
}));

function Budget() {
	const theme = useTheme();
	const classes = useStyles();
	const dispatch = useDispatch();
	const budget = useSelector((state) => state.budget);
	const [currentView, setCurrentView] = React.useState('table');
	const { categorySlug } = useParams();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const [runTutorial, setRunTutorial] = React.useState(false);

	const steps = [
		{
			content: <Typography>Your budget has been prefilled with suggested categories.</Typography>,
			placement: 'center',
			target: 'body'
		},
		{
			content: <Typography>Let's look at the Venue category</Typography>,
			placement: 'left',
			disableBeacon: true,
			disableOverlayClose: true,
			hideCloseButton: true,
			hideFooter: true,
			spotlightClicks: true,
			target: '[data-cy=view-btn-0]'
		}
	];

	const stepsMobile = [
		{
			content: <Typography>Your budget has been prefilled with suggested categories.</Typography>,
			placement: 'center',
			target: 'body'
		},
		{
			content: <Typography>Tap on the Venue category to continue</Typography>,
			placement: 'bottom',
			disableBeacon: true,
			disableOverlayClose: true,
			hideCloseButton: true,
			hideFooter: true,
			spotlightClicks: true,
			target: '#MUIDataTableBodyRow-0'
		}
	];

	useEffect(() => {
		if (!budget.result.tourComplete && !categorySlug)
			setRunTutorial(true);
	}, [categorySlug, budget.result.tourComplete]);

	useEffect(() => {
		dispatch(budgetActions.loadBudget(history))
			.catch(err => {
				enqueueSnackbar('Unable to load data', {
					variant: 'error'
				});
			});
	}, [dispatch, history, enqueueSnackbar])



	const handleChangeView = (view) => {
		if (view !== null) setCurrentView(view);
	}

	const handleJoyrideCallback = data => {
		const { type } = data;

		if (type === EVENTS.TOUR_END && categorySlug) {
			dispatch(budgetActions.completeSetup({
				weddingCity: budget.result.weddingCity,
				myName: budget.result.myName,
				partnerName: budget.result.partnerName,
				weddingDate: budget.result.weddingDate,
				theme: budget.result.theme,
				seedBudget: false,
				knownBudget: budget.result.knownBudget,
				budgetAmount: budget.result.budgetAmount,
				tourComplete: true
			}))
		}
	};

	return (
		<Page
			className={classes.root}
			title="Budget | weddingbudget.io"
		>
			<Hidden smDown>
				<Joyride
					callback={handleJoyrideCallback}
					continuous={true}
					// getHelpers={this.getHelpers}
					run={runTutorial}
					scrollToFirstStep={true}
					showProgress={true}
					showSkipButton={true}
					steps={steps}
					styles={{
						options: {
							zIndex: 10000,
							primaryColor: theme.palette.primary.main
						},
					}}
				/>
			</Hidden>
			<Hidden mdUp>
				<Joyride
					callback={handleJoyrideCallback}
					continuous={true}
					// getHelpers={this.getHelpers}
					run={runTutorial}
					scrollToFirstStep={true}
					showProgress={true}
					showSkipButton={true}
					steps={stepsMobile}
					styles={{
						options: {
							zIndex: 10000,
							primaryColor: theme.palette.primary.main
						},
					}}
				/>
			</Hidden>



			<Container
				maxWidth={false}
				className={classes.container}
			>
				<Header onChangeView={handleChangeView} categorySlug={categorySlug} currentView={currentView} />
				<br />
				{!categorySlug && budget.result.line_items && <Overview
					budgetLineItems={budget.result.line_items}
					categoryData={budget.categoryData}
					view={currentView} />}
				{categorySlug && <BudgetComponent selectedCategory={categorySlug} />}
			</Container>
		</Page>
	);
}

export default Budget;
