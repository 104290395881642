import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Card,
	Typography,
	Grid,
	Tooltip
} from '@material-ui/core';
import Label from 'src/components/Label';
import { useSelector } from 'react-redux';
import DateFnsAdapter from "@date-io/date-fns";
import differenceInDays from "date-fns/differenceInDays";
import { FormattedNumber } from 'react-intl';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const dateFns = new DateFnsAdapter();

const useStyles = makeStyles((theme) => ({
	root: {},
	content: {
		padding: 0
	},
	item: {
		padding: theme.spacing(3),
		textAlign: 'center',
		[theme.breakpoints.up('md')]: {
			'&:not(:last-of-type)': {
				borderRight: `1px solid ${theme.palette.divider}`
			}
		},
		[theme.breakpoints.down('sm')]: {
			'&:not(:last-of-type)': {
				borderBottom: `1px solid ${theme.palette.divider}`
			}
		}
	},
	titleWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	label: {
		marginLeft: theme.spacing(1)
	},
	overline: {
		marginTop: theme.spacing(1)
	},
	indicator: {
		fontSize: 14,
		marginLeft: theme.spacing(1)
	},
}));

function Statistics({ className, ...rest }) {
	const classes = useStyles();
	const budgetData = useSelector((state) => state.budget.result);



	if (!budgetData) {
		return null;
	}

	const budgetedTotal = budgetData.line_items
		.map(i => i.budgeted)
		.reduce((a, b) => a + b, 0);

	const actualTotal = budgetData.line_items
		.map(i => i.actual)
		.reduce((a, b) => a + b, 0);

	const percentOver = Math.ceil(
		((actualTotal - budgetedTotal) / budgetedTotal) * 100
	);

	const budgetPercentOver = Math.ceil(
		((budgetedTotal - budgetData.budgetAmount) / budgetData.budgetAmount) * 100
	);


	const budgetAmountOver = budgetData.budgetAmount - budgetedTotal;

	const getDaysRemaining = (weddingDate) => {
		let weddingDateObject = dateFns.date(weddingDate);
		const today = dateFns.date(new Date());
		today.setHours(0, 0, 0, 0);
		weddingDateObject.setHours(0, 0, 0, 0);
		const daysRemaining = differenceInDays(weddingDateObject, today);
		return daysRemaining >= 0 ? daysRemaining : 0;
	}

	const daysRemaining = getDaysRemaining(budgetData.weddingDate);

	const totalPaidPayments = budgetData.line_items
		.map(lineItem => {
			let totalPaidPaymentsAmount = 0;

			if (lineItem.payments.expenses.length > 0) {
				totalPaidPaymentsAmount = lineItem.payments.expenses
					.map(e => e.amount)
					.reduce((a, b) => a + b, 0);
			} else if (lineItem.payments.paymentSchedule.length > 0) {
				const paidLineItems = lineItem.payments.paymentSchedule
					.filter(ps => ps.paid)

				if (paidLineItems.length > 0) {
					totalPaidPaymentsAmount = paidLineItems
						.map(ps => ps.amount)
						.reduce((a, b) => a + b, 0);
				}

			}

			return totalPaidPaymentsAmount;
		})
		.reduce((a, b) => a + b, 0);

	const outstandingBalance = budgetedTotal - totalPaidPayments;

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<Grid
				alignItems="center"
				container
				justify="space-between"
			>
				{daysRemaining > 0 && <Grid
					className={classes.item}
					item
					md={3}
					sm={6}
					xs={12}
				>
				 <React.Fragment>
					<Typography variant="h2">
						{daysRemaining}
					</Typography>
					<Typography
						className={classes.overline}
						variant="overline"
					>
						{daysRemaining > 1 ? 'days left' : 'day left!'}
					</Typography>
					</React.Fragment>
				
				</Grid>}
				<Grid
					className={classes.item}
					item
					md={daysRemaining > 0 ? 3 : 4}
					sm={6}
					xs={12}
					id="originalBudgetContainer"
				>

					<div className={classes.titleWrapper}>
						<Typography variant="h2" id="currentBudgetText">
							<FormattedNumber style={`currency`} currency="USD" value={budgetedTotal} />
						</Typography>
						{budgetPercentOver > 0 &&
							<Label
								className={classes.label}
								color="error"
								variant="contained"
							>
								+{budgetPercentOver}%
							</Label>
						}
					</div>

					<Typography
						className={classes.overline}
						variant="overline"
					>
						Current Budget
						
					</Typography>
					<Typography
						className={classes.overline}
						variant="overline"
					>
					
					{budgetAmountOver < 0 &&
					<Tooltip title={`You are $${Math.abs(budgetAmountOver)} over your budget goal of $${budgetData.budgetAmount}.`}><InfoOutlinedIcon className={classes.indicator} /></Tooltip>}
					</Typography>
					
					{!budgetData.knownBudget &&
						<Tooltip title="Based on the average budget for weddings in your city">
							<span  />
						</Tooltip>
					}
				</Grid>
				<Grid
					className={classes.item}
					item
					md={daysRemaining > 0 ? 3 : 4}
					sm={6}
					xs={12}
				>
					<div className={classes.titleWrapper}>

						<Typography variant="h2">
							<FormattedNumber style={`currency`} currency="USD" value={actualTotal} />
						</Typography>
						{percentOver > 0 &&
							<Label
								className={classes.label}
								color="error"
								variant="contained"
							>
								+{percentOver}%
                        </Label>
						}
					</div>

					<Typography
						className={classes.overline}
						variant="overline"
					>
						Final Cost
          			</Typography>
				</Grid>
				<Grid
					className={classes.item}
					item
					md={daysRemaining > 0 ? 3 : 4}
					sm={6}
					xs={12}
				>
					<Typography
						variant="h2"
					>
						<FormattedNumber style={`currency`} currency="USD" value={outstandingBalance} />
					</Typography>
					<Typography
						className={classes.overline}
						variant="overline"
					>
						Outstanding balance
          </Typography>
				</Grid>
			</Grid>
		</Card>
	);
}

Statistics.propTypes = {
	className: PropTypes.string
};

export default Statistics;
