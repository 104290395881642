import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import uuid from 'uuid/v1';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import PaymentIcon from '@material-ui/icons/PaymentOutlined';
import AddPhotoIcon from '@material-ui/icons/AddPhotoAlternate';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import MailIcon from '@material-ui/icons/MailOutlineOutlined';

const useStyles = makeStyles((theme) => ({
  root: {},
  value: {
    fontWeight: theme.typography.fontWeightMedium
  },
  type: {
    fontWeight: theme.typography.fontWeightMedium
  },
  listItem: {
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    }
  },
}));

const notifications = [
  {
    id: uuid(),
    value: 6,
    type: 'budget',
    message: 'Review budget categories',
    link: '/budget'
  },
  {
    id: uuid(),
    value: 2,
    type: 'budget',
    message: 'Review expense budgets',
    link: '/budget/detail/venue'
  },
  {
    id: uuid(),
    value: 1,
    type: 'payout',
    message: 'Enter known payment due dates',
    link: '/budget'
  },
];

const icons = {
  invite: <SendIcon />,
  message: <MailIcon />,
  payout: <PaymentIcon />,
  photo: <AddPhotoIcon />,
  budget: <AccountBalanceWalletIcon />
};

function Notifications({ className, ...rest }) {
  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <List>
        {notifications.map((notification, i) => (
          <ListItem
            divider={i < notifications.length - 1}
            key={notification.id}
            component={RouterLink}
            className={classes.listItem}
            to={notification.link}

          >
            <ListItemIcon>{icons[notification.type]}</ListItemIcon>
            <ListItemText>
              <Typography variant="body1">
                {notification.message}
              </Typography>
            </ListItemText>
            {/* <ListItemSecondaryAction>
              <Tooltip title="View">
                <IconButton
                  edge="end"
                  size="small"
                >
                  <ArrowForwardIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction> */}
          </ListItem>
        ))}
      </List>
    </Card>
  );
}

Notifications.propTypes = {
  className: PropTypes.string
};

export default Notifications;
