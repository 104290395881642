import React from 'react';
import PropTypes from 'prop-types';
import {
	Table,
	TableBody,
	TableCell,
	TableRow,
	TableHead,
	TableContainer,
	Paper,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Hidden
} from "@material-ui/core";
import { FormattedDate, FormattedNumber } from "react-intl";
import Label from 'src/components/Label';
import { useDispatch } from 'react-redux';
import budgetActions from 'src/redux/actions/budgetActions';
import _ from 'lodash';

const { saveBudgetLineItem } = budgetActions;

function MarkPaidDialog({ open, close, payment, selectedIndex }) {
	let amountPaid = 0;
	if (payment && selectedIndex >= 0 && payment.paymentSchedule[selectedIndex]) {
		amountPaid = payment.paymentSchedule[selectedIndex].amount;
	}
	return (
		<Dialog open={open} onClose={close} aria-labelledby="form-dialog-title" maxWidth={'sm'} fullWidth>
			<DialogTitle id="form-dialog-title">Mark as Paid</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Mark payment #{selectedIndex + 1} - <FormattedNumber style={`currency`} currency="USD" value={amountPaid} /> as paid?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => close(false)} color="primary" id="markPaidCancelBtn">
					Cancel
		  		</Button>
				<Button onClick={() => close(true)} color="primary" id="markPaidOKBtn">
					OK
		  		</Button>
			</DialogActions>
		</Dialog>
	)
}

function PaymentList({
	paymentData,
	lineItem
}) {

	const [showMarkPaidDialog, setShowMarkPaidDialog] = React.useState(false);
	const [selectedIndex, setSelectedIndex] = React.useState(0);
	const dispatch = useDispatch();

	const openDialog = (index) => {
		setSelectedIndex(index);
		setShowMarkPaidDialog(true);
	}

	const handleClose = (save) => {
		setShowMarkPaidDialog(false);
		if (save) {
			// 'OK' pressed
			const updatedLineItem = _.cloneDeep(lineItem);
			updatedLineItem.payments.paymentSchedule[selectedIndex].paid = true;

			dispatch(saveBudgetLineItem(updatedLineItem));
		}
	}

	const getLabelData = (payment) => {

		let { paid: status } = payment;

		let color, text;
		switch (status) {
			case true:
				color = 'success';
				text = 'Paid';
				break;

			case false:
				color = 'warning';
				text = 'Pending';
				break;

			default:
				color = 'warning';
				text = 'Unknown';
		}

		if (!payment.paid && new Date(payment.date) < new Date()) {
			color = 'error';
			text = 'overdue';
		}

		return { color, text }
	}

	const useExpenses = paymentData.expenses.length > 0;

	return (
		<React.Fragment>
			<TableContainer component={Paper}>
				<Table aria-label="payment table">
					<TableHead>
						<TableRow>
							{useExpenses &&
								<React.Fragment>
									<TableCell>Name</TableCell>
									<TableCell>Amount</TableCell>
									<TableCell>Date Paid</TableCell>
								</React.Fragment>
							}
							{!useExpenses &&
								<React.Fragment>
									<TableCell>#</TableCell>
									<TableCell>Amount</TableCell>
									<Hidden smDown><TableCell>Status</TableCell></Hidden>
									<TableCell>Due Date</TableCell>
									<TableCell></TableCell>
								</React.Fragment>
							}
						</TableRow>
					</TableHead>
					<TableBody>
						{useExpenses && paymentData.expenses.map((row, index) => (
							<TableRow key={index}>

								<TableCell>{row.name}</TableCell>
								<TableCell><FormattedNumber style={`currency`} currency="USD" value={row.amount} /></TableCell>
								<TableCell><FormattedDate value={row.date} /> </TableCell>
							</TableRow>
						))}

						{!useExpenses && paymentData.paymentSchedule.map((row, index) => (
							<TableRow key={index}>
								<TableCell>{index + 1}</TableCell>
								<TableCell><FormattedNumber style={`currency`} currency="USD" value={row.amount} /></TableCell>
								<Hidden smDown><TableCell><Label color={getLabelData(row).color}>{getLabelData(row).text}</Label></TableCell></Hidden>
								<TableCell><FormattedDate value={row.date} /> </TableCell>
								<TableCell>{!row.paid && <Button onClick={() => openDialog(index)}>Mark as Paid</Button>}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<MarkPaidDialog
				payment={paymentData}
				lineItem={lineItem}
				open={showMarkPaidDialog}
				selectedIndex={selectedIndex}
				close={handleClose}
			/>
		</React.Fragment>
	);
}

PaymentList.propTypes = {
	paymentData: PropTypes.object.isRequired
};

export default PaymentList;