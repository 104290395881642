import budgetActions from 'src/redux/actions/budgetActions';
import _ from 'lodash';

const initialState = {
    loggedIn: true,
    user: {
        first_name: 'Shen',
        last_name: 'Zhi',
        email: 'demo@devias.io',
        avatar: '/images/avatars/avatar_11.png',
        bio: 'Brain Director',
        role: 'ADMIN' // ['GUEST', 'USER', 'ADMIN']
    },
    result: {},
    categoryData: [],
    currentCategory: {},
    paymentData: {},
    error: false,
    heroImage: '/images/hero-upload.jpg',
    paymentTracker: {},
    loading: false,

    health: {}
};

const mapCategoryData = data => {
    let cdata = {};
    data.line_items && data.line_items.forEach(d => {
        if (!cdata[d.category]) cdata[d.category] = [];
        cdata[d.category].push(d)
    });
    return cdata;
}

const updateBudgetWithSavedLineItem = (updatedLineItem, budget) => {
    const updatedBudget = _.cloneDeep(budget);

    // If this is a new record, add it
    const existingRecord = updatedBudget.line_items.find(lineItem => lineItem._id === updatedLineItem._id);
    if (typeof existingRecord === 'undefined') {
        updatedBudget.line_items.push(updatedLineItem);
    } else {
        const updatedLineItems = updatedBudget.line_items.map(element => {
            if (element._id === updatedLineItem._id) return updatedLineItem;
            return element;
        });
        updatedBudget.line_items = updatedLineItems;
    }

    return updatedBudget;
}

const deleteCategoryFromBudget = (categoryToDelete, budget) => {
    const updatedBudget = _.cloneDeep(budget);
    updatedBudget.line_items = updatedBudget.line_items.filter(item => item.category !== categoryToDelete);
    return updatedBudget;
}

const deleteLineItem = (lineItemToDelete, budget) => {
    const updatedBudget = _.cloneDeep(budget);
    updatedBudget.line_items = updatedBudget.line_items.filter(item => item._id !== lineItemToDelete._id);
    return updatedBudget;
}

const budgetReducer = (state = initialState, action) => {
    switch (action.type) {

        case budgetActions.CHECK_SETUP:
            return {
                ...state,
                loading: true
            }
        case budgetActions.CHECK_SETUP_RESULT:
            return {
                ...state,
                loading: false
            }

        case budgetActions.CHECK_SETUP_FAILED:
            return {
                ...state,
                loading: false
            }

        case budgetActions.LOAD_BUDGET_RESULT:
            return {
                ...state,
                result: action.data,
                categoryData: mapCategoryData(action.data),
                loading: false
            };

        case budgetActions.SELECT_CATEGORY:
            // const { categoryData } = state;
            return {
                ...state,
                currentCategory: {
                    name: action.data,
                    data: state.categoryData[action.data],
                    loading: false
                }
            }

        case budgetActions.CREATE_CATEGORY_RESULT:
            return {
                ...state
            }

        case budgetActions.DELETE_CATEGORY_RESULT:
            const updatedBudgetWithoutCategory = deleteCategoryFromBudget(action.request.category, state.result);
            return {
                ...state,
                result: updatedBudgetWithoutCategory,
                categoryData: mapCategoryData(updatedBudgetWithoutCategory)
            }

        case budgetActions.SAVE_BUDGET_LINE_ITEM:
            return {
                ...state,
                loading: true
            }

        case budgetActions.SAVE_BUDGET_LINE_ITEM_RESULT:
            const updatedBudget = updateBudgetWithSavedLineItem(action.data, state.result);
            return {
                ...state,
                loading: false,
                result: updatedBudget,
                categoryData: mapCategoryData(updatedBudget),
            }

        case budgetActions.DELETE_BUDGET_LINE_ITEM_RESULT:
            const updatedBudget2 = deleteLineItem(action.request, state.result);
            return {
                ...state,
                result: updatedBudget2,
                categoryData: mapCategoryData(updatedBudget2),
            }

        // Payments

        case budgetActions.LOAD_PAYMENT_DATA:
            const paymentDataLookup = state.result.line_items.find(d => d._id === action.id);
            const paymentData = typeof paymentDataLookup !== 'undefined' ? paymentDataLookup : {};
            return {
                ...state,
                paymentData
            }

        case budgetActions.UPDATE_HERO_IMAGE_RESULT:
            return {
                ...state,
                result: {
                    ...state.result,
                    heroImage: action.data.path
                }
            }

        case budgetActions.GET_PAYMENT_TRACKER_RESULT:
            return {
                ...state,
                paymentTracker: action.data
            }

        case budgetActions.RENAME_CATEGORY_RESULT:
            return {
                ...state,
                result: action.data,
                categoryData: mapCategoryData(action.data)
            }

        case budgetActions.GET_BUDGET_HEALTH_RESULT:
            return {
                ...state,
                health: action.data
            }

        default: {
            return state;
        }
    }
};

export default budgetReducer;
