import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Card,
	Typography,
	Grid
} from '@material-ui/core';
import Label from 'src/components/Label';
import { FormattedNumber } from 'react-intl';

const useStyles = makeStyles((theme) => ({
	root: {},
	content: {
		padding: 0
	},
	item: {
		padding: theme.spacing(3),
		textAlign: 'center',
		[theme.breakpoints.up('md')]: {
			'&:not(:last-of-type)': {
				borderRight: `1px solid ${theme.palette.divider}`
			}
		},
		[theme.breakpoints.down('sm')]: {
			'&:not(:last-of-type)': {
				borderBottom: `1px solid ${theme.palette.divider}`
			}
		}
	},
	valueContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	label: {
		marginLeft: theme.spacing(1)
	}
}));

function PaymentOverview({ data, ...rest }) {
	const classes = useStyles();

	let percentOver = 0;
	if (data) {
		percentOver = Math.ceil(((data.actual - data.budgeted) / data.budgeted) * 100);
	}
	let totalPaidPaymentsAmount = 0;
	let outstandingBalance = 0;

	if (data.payments.expenses.length > 0) {
		totalPaidPaymentsAmount = data.payments.expenses
			.map(e => e.amount)
			.reduce((a, b) => a + b, 0);

		outstandingBalance = data.budgeted - totalPaidPaymentsAmount;


	} else if (data.payments.paymentSchedule.length > 0) {
		const paidLineItems = data.payments.paymentSchedule
			.filter(ps => ps.paid)

		if (paidLineItems.length > 0) {
			totalPaidPaymentsAmount = paidLineItems
				.map(ps => ps.amount)
				.reduce((a, b) => a + b, 0);
		}
		const totalBalance = data.payments.paymentSchedule
			.map(ps => ps.amount)
			.reduce((a, b) => a + b, 0);

		outstandingBalance = totalBalance - totalPaidPaymentsAmount;

	}



	return (
		<Card
			{...rest}
			className={clsx(classes.root)}
		>
			<Grid
				alignItems="center"
				container
				justify="space-between"
			>
				<Grid
					className={classes.item}
					item
					md={4}
					sm={4}
					xs={12}
				>
					<Typography
						component="h2"
						gutterBottom
						variant="overline"
					>
						Budgeted
					</Typography>
					<div className={classes.valueContainer} data-cy="budgeted-container">
						<Typography variant="h3">
							<FormattedNumber style={`currency`} currency="USD" value={data.budgeted} />
						</Typography>
					</div>
				</Grid>
				<Grid
					className={classes.item}
					item
					md={4}
					sm={4}
					xs={12}
				>
					<Typography
						component="h2"
						gutterBottom
						variant="overline"
					>
						Final Cost
					</Typography>
					<div className={classes.valueContainer} data-cy="actual-container">
						<Typography variant="h3">
							<FormattedNumber style={`currency`} currency="USD" value={data.actual} />
						</Typography>
						{percentOver > 0 &&
							<Label
								className={classes.label}
								color="error"
								variant="contained"
							>
								+{percentOver}%
						</Label>
						}
					</div>
				</Grid>
				<Grid
					className={classes.item}
					item
					md={4}
					sm={4}
					xs={12}
				>
					<Typography
						component="h2"
						gutterBottom
						variant="overline"
					>
						{data.payments.expenses.length > 0 ? 'Available' : 'Unpaid Balance'}
					</Typography>
					<div className={classes.valueContainer} data-cy="actual-container">
						<Typography variant="h3">
							<FormattedNumber style={`currency`} currency="USD" value={outstandingBalance} />
						</Typography>
					</div>
				</Grid>

			</Grid>
		</Card>
	);
}

PaymentOverview.propTypes = {
	data: PropTypes.object.isRequired
};

export default PaymentOverview;
