import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    Typography,
    CardContent,
    CardHeader,
    Divider
} from '@material-ui/core';
import { FormattedNumber } from 'react-intl';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {},
    value: {
        fontWeight: theme.typography.fontWeightMedium
    },
    type: {
        fontWeight: theme.typography.fontWeightMedium
    }
}));


function PiggyBank({ className, ...rest }) {
    const classes = useStyles();
    const budgetData = useSelector(state => state.budget.result);
    const budgetedTotal = budgetData.line_items
        .map(i => i.budgeted)
        .reduce((a, b) => a + b, 0);

    const piggyBank = budgetData.budgetAmount - budgetedTotal;


    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardHeader
                title="PiggyBank&reg;"
            />
            <Divider />
            <CardContent>
                <Typography variant="h5">In your PiggyBank: <FormattedNumber style={`currency`} currency="USD" value={piggyBank} /></Typography><br />
                <Typography>Your PiggyBank&reg; tracks your current budgeting against your budget goal. </Typography><br />
                <Typography>Use this bank to easily move money to other line items without exceeding your budget!</Typography>
            </CardContent>
        </Card>
    );
}

PiggyBank.propTypes = {
    className: PropTypes.string
};

export default PiggyBank;
