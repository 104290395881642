/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import SetupLayout from './layouts/Setup';
import DashboardLayout from './layouts/Dashboard';
import DashboardAnalyticsView from './views/DashboardAnalytics';
import DashboardDefaultView from './views/DashboardDefault';
import OverviewView from './views/Overview';
import BudgetView from './views/Budget';
import DashboardView from './views/Dashboard';
import PaymentDetailView from './views/PaymentDetails';

export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('src/views/Login'))
      },
      {
        path: '/auth/register',
        exact: true,
        component: lazy(() => import('src/views/Register'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('src/views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('src/views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('src/views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/setup',
    component: SetupLayout,
    routes: [
      {
        path: '/setup/complete',
        exact: true,
        component: lazy(() => import('src/views/Setup'))
      },
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/components/typography',
        exact: true,
        component: lazy(() => import('src/views/Typography'))
      },
      {
        path: '/dashboard',
        exact: true,
        component: DashboardView
      },
      {
        path: '/payments',
        exact: true,
        component: lazy(() => import('src/views/Payments'))
      },
      {
        path: '/payments/tracker',
        exact: true,
        component: lazy(() => import('src/views/PaymentTracker'))
      },
      {
        path: '/payments/history',
        exact: true,
        component: lazy(() => import('src/views/PaymentHistory'))
      },

      {
        path: '/payments/:lid',
        exact: true,
        component: PaymentDetailView
      },
      {
        path: '/payment-wizard/:lid',
        exact: true,
        component: lazy(() => import('src/views/PaymentWizard'))
      },
      {
        path: '/log-expense/:lid',
        exact: true,
        component: lazy(() => import('src/views/LogExpense'))
      },
      {
        path: '/dashboards/analytics',
        exact: true,
        component: DashboardAnalyticsView
      },
      {
        path: '/dashboards/default',
        exact: true,
        component: DashboardDefaultView
      },
      {
        path: '/mail',
        exact: true,
        component: lazy(() => import('src/views/Mail'))
      },
      {
        path: '/overview',
        exact: true,
        component: OverviewView
      },
      {
        path: '/budget',
        exact: true,
        component: BudgetView
      },
      {
        path: '/budget?chart',
        exact: true,
        component: BudgetView
      },
      {
        path: '/budget/detail/:categorySlug',
        exact: true,
        component: BudgetView
      },

      {
        path: '/profile/:id',
        exact: true,
        component: lazy(() => import('src/views/Profile'))
      },
      {
        path: '/profile/:id/:tab',
        exact: true,
        component: lazy(() => import('src/views/Profile'))
      },
      {
        path: '/settings',
        exact: true,
        component: lazy(() => import('src/views/Settings'))
      },
      {
        path: '/settings/:tab',
        exact: true,
        component: lazy(() => import('src/views/Settings'))
      },
      {
        path: '/health',
        exact: true,
        component: lazy(() => import('src/views/Health'))
      },
      {
        path: '/getting-started',
        exact: true,
        component: lazy(() => import('src/views/GettingStarted'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];
