import * as actionTypes from 'src/redux/actions';

const initialState = {
  loggedIn: true,
  user: {
    nickname: '',
    name: '',
    picture: '',
    updated_at: '',
    email: '',
    email_verified: false,
    sub: '',
    role: ''
  },
  token: ''
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_LOGIN: {
      const user = { ...action.user, role: 'ADMIN' };
      return {
        ...state,
        token: action.token,
        user
      };
    }

    case actionTypes.SESSION_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        user: {
          role: 'GUEST'
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;
