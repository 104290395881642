import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Typography,
	Grid,
	Button,
	Hidden,
	Card,
	CardMedia,
	colors
} from '@material-ui/core';
import AddPhotoIcon from '@material-ui/icons/AddPhotoAlternate';
import budgetActions from 'src/redux/actions/budgetActions';
import moment from 'moment';
import _ from 'lodash';

const { updateHeroImage } = budgetActions;

const useStyles = makeStyles((theme) => ({
	root: {},
	summaryButton: {
		backgroundColor: theme.palette.common.white
	},
	barChartIcon: {
		marginRight: theme.spacing(1)
	},
	image: {
		width: '100%',
		maxHeight: 400,
		minHeight: 400,
	},
	card: {
		display: 'flex',
	},
	uploadImageButton: {
		height: '100%'
	},
	cover: {
		width: '100%',
		maxHeight: 400,
		minHeight: 400,
		position: 'relative',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		'&:before': {
			position: 'absolute',
			content: '" "',
			top: 0,
			left: 0,
			height: '100%',
			width: '100%',
			backgroundImage: 'linear-gradient(-180deg, rgba(0,0,0,0.00) 58%, rgba(0,0,0,0.32) 100%)'
		},
		'&:hover': {
			'& $changeButton': {
				visibility: 'visible'
			}
		}
	},
	changeButton: {
		visibility: 'hidden',
		position: 'absolute',
		bottom: theme.spacing(3),
		right: theme.spacing(3),
		backgroundColor: colors.blueGrey[900],
		color: theme.palette.common.white,
		[theme.breakpoints.down('md')]: {
			top: theme.spacing(3),
			bottom: 'auto'
		},
		'&:hover': {
			backgroundColor: colors.blueGrey[900]
		}
	},
}));

function Header({
	className, ...rest }) {
	const classes = useStyles();

	const dispatch = useDispatch();
	const budgetData = useSelector(state => state.budget.result);

	const handleFileUpload = (event) => {
		const files = event.target.files;
		const formData = new FormData()
		formData.append('file', files[0]);
		dispatch(updateHeroImage(formData, files[0]));
	}

	const WeddingDateText = () => {
		const weddingDate = moment(budgetData.weddingDate);
		const today = moment(new Date()).startOf('day');

		let returnComponent = null;
		if(weddingDate.startOf('day').isSame(today)) {
			returnComponent = <React.Fragment>IT'S FINALLY HERE! TODAY IS YOUR BIG DAY!</React.Fragment>;
		}else if(weddingDate.startOf('day').isAfter(today)) {
			returnComponent = <React.Fragment>Your big day is on {moment(budgetData.weddingDate).format('MMMM Do YYYY')} in {budgetData.weddingCity}! </React.Fragment>;
		}

		return (returnComponent);
	}
	
	return (
		<div
			className={clsx(classes.root, className)}
		>
			<Grid
				alignItems="center"
				container
				justify="space-between"
				spacing={3}
			>
				<Grid
					item
					md={6}
					xs={12}
				>
					<Typography
						component="h2"
						gutterBottom
						variant="overline"
						color="textPrimary"
					>
						Home
		  			</Typography>
					<Typography
						component="h1"
						gutterBottom
						variant="h3"
						color="textPrimary"
					>
						Hi, {budgetData.myName}!
					</Typography>
					<Typography
						gutterBottom
						variant="subtitle2"
						color="textPrimary"
					>

					<WeddingDateText />
					{/**moment(budgetData.weddingDate).isSame(new Date()) ? 
						<React.Fragment>hi</React.Fragment> :
						
					*/}
					</Typography>
					<Typography
						gutterBottom
						variant="subtitle1"
						color="textPrimary"
					>
						Here's a summary of your wedding budget
					  </Typography>
				</Grid>
				<Hidden smDown>
					<Grid
						item
						md={6}
					>
						<Card className={classes.card}>
							<CardMedia title="Cover"
								className={classes.cover}
								image={!_.isEmpty(budgetData.heroImage) ? budgetData.heroImage : '/images/hero-upload.jpg' }>

								<input
									accept="image/*"
									className={classes.input}
									style={{ display: 'none' }}
									id="raised-button-file"
									onChange={(e) => handleFileUpload(e)}
									type="file"
								/>
								<label htmlFor="raised-button-file">
									<Button
										className={classes.changeButton}
										variant="contained"
										component="span"
									>

										<AddPhotoIcon className={classes.addPhotoIcon} />
										Change Cover
        						</Button>
								</label>
							</CardMedia>
						</Card>
					</Grid>
				</Hidden>
			</Grid>
		</div>
	);
}

Header.propTypes = {
	className: PropTypes.string
};

export default Header;

// function mapStateToProps(state) {
// 	const budgetData = state.budget.result
// 	const { heroImage } = state.budget;
// 	return { budgetData, heroImage }
// }
// export default connect(mapStateToProps, { updateHeroImage })(Header);
