/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
    AppBar,
    Badge,
    Button,
    IconButton,
    Toolbar,
    Hidden,
    colors,
    Popper,
    Paper,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ClickAwayListener,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import PricingModal from 'src/components/PricingModal';
import { useAuth0 } from "../../react-auth0-spa";
import DaysLeft from './DaysLeft';
import Label from 'src/components/Label';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
    },
    flexGrow: {
        flexGrow: 1
    },
    search: {
        backgroundColor: 'rgba(255,255,255, 0.1)',
        borderRadius: 4,
        flexBasis: 300,
        height: 36,
        padding: theme.spacing(0, 2),
        display: 'flex',
        alignItems: 'center'
    },
    searchIcon: {
        marginRight: theme.spacing(2),
        color: 'inherit'
    },
    searchInput: {
        flexGrow: 1,
        color: 'inherit',
        '& input::placeholder': {
            opacity: 1,
            color: 'inherit'
        }
    },
    searchPopper: {
        zIndex: theme.zIndex.appBar + 100
    },
    searchPopperContent: {
        marginTop: theme.spacing(1)
    },
    trialButton: {
        marginLeft: theme.spacing(2),
        color: theme.palette.common.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    trialExpired: {
        backgroundColor: colors.red[600],
        '&:hover': {
            backgroundColor: colors.red[900]
        }
    },
    trialIcon: {
        marginRight: theme.spacing(1)
    },
    menuButton: {
        marginRight: theme.spacing(1)
    },
    chatButton: {
        marginLeft: theme.spacing(1)
    },
    notificationsButton: {
        marginLeft: theme.spacing(1)
    },
    notificationsBadge: {
        backgroundColor: colors.orange[600]
    },
    logoutButton: {
        marginLeft: theme.spacing(2)
    },
    logoutIcon: {
        marginRight: theme.spacing(1)
    }
}));

const popularSearches = [
    'Devias React Dashboard',
    'Devias',
    'Admin Pannel',
    'Project',
    'Pages'
];


function TopBar({
    onOpenNavBarMobile,
    className,
    hideNav,
    ...rest
}) {
    const classes = useStyles();
    const searchRef = useRef(null);
    const notificationsRef = useRef(null);
    const [openSearchPopover, setOpenSearchPopover] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [openNotifications, setOpenNotifications] = useState(false);
    const [openChatBar, setOpenChatBar] = useState(false);
    const [pricingModalOpen, setPricingModalOpen] = useState(false);
    const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
    const budget = useSelector(state => state.budget);

    const handlePricingModalOpen = () => {
        setPricingModalOpen(true);
    };

    const handlePricingModalClose = () => {
        setPricingModalOpen(false);
    };

    const handleChatBarOpen = () => {
        setOpenChatBar(true);
    };

    const handleChatBarClose = () => {
        setOpenChatBar(false);
    };

    const handleNotificationsOpen = () => {
        setOpenNotifications(true);
    };

    const handleNotificationsClose = () => {
        setOpenNotifications(false);
    };

    const handleSearchPopverClose = () => {
        setOpenSearchPopover(false);
    };

    return (
        <AppBar
            {...rest}
            className={clsx(classes.root, className)}
            color="primary"
        >
            <Toolbar>
                {!hideNav && <Hidden lgUp>
                    <IconButton
                        id="mobileNavButton"
                        className={classes.menuButton}
                        color="inherit"
                        onClick={onOpenNavBarMobile}
                    >
                        <MenuIcon />
                    </IconButton>
                </Hidden>}
                <RouterLink to="/dashboard">
                    <img
                        alt="Logo"
                        src="/images/logos/logo-wb.png"
                        height="40"
                    />
                </RouterLink>
                <Label color="success">{process.env.REACT_APP_VERSION}</Label>
                <div className={classes.flexGrow} />
                <Hidden smDown>
                    {/* <div
                        className={classes.search}
                        ref={searchRef}
                    >
                        <SearchIcon className={classes.searchIcon} />
                        <Input
                            className={classes.searchInput}
                            disableUnderline
                            onChange={handleSearchChange}
                            placeholder="Search people &amp; places"
                            value={searchValue}
                        />
                    </div> */}
                    <Popper
                        anchorEl={searchRef.current}
                        className={classes.searchPopper}
                        open={openSearchPopover}
                        transition
                    >
                        <ClickAwayListener onClickAway={handleSearchPopverClose}>
                            <Paper
                                className={classes.searchPopperContent}
                                elevation={3}
                            >
                                <List>
                                    {popularSearches.map((search) => (
                                        <ListItem
                                            button
                                            key={search}
                                            onClick={handleSearchPopverClose}
                                        >
                                            <ListItemIcon>
                                                <SearchIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={search} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>
                        </ClickAwayListener>
                    </Popper>
                    {/*
                        Hidden for beta
                        <DaysLeft
                        handleOnClick={handlePricingModalOpen}
                        budget={budget}
                    classes={classes} /> */}
                </Hidden>
                <Hidden smDown>
                    {/* <IconButton
                        className={classes.notificationsButton}
                        color="inherit"
                        onClick={handleNotificationsOpen}
                        ref={notificationsRef}
                    >
                        <Badge
                            badgeContent={notifications.length}
                            classes={{ badge: classes.notificationsBadge }}
                            variant="dot"
                        >
                            <NotificationsIcon />
                        </Badge>
                    </IconButton> */}
                    <div>
                        {!isAuthenticated && (
                            <Button color="inherit" onClick={() => loginWithRedirect({})}>Log in</Button>
                        )}
                        {isAuthenticated && <Button color="inherit" className={classes.logoutButton} onClick={() => logout(
                            {
                                returnTo: window.location.protocol + '//' + window.location.host + '/auth/login'
                            }
                        )}>Log out</Button>}
                    </div>
                </Hidden>
            </Toolbar>
            {/* <NotificationsPopover
                anchorEl={notificationsRef.current}
                notifications={notifications}
                onClose={handleNotificationsClose}
                open={openNotifications}
            /> */}
            <PricingModal
                onClose={handlePricingModalClose}
                open={pricingModalOpen}
            />
            {/* <ChatBar
                onClose={handleChatBarClose}
                open={openChatBar}
            /> */}
        </AppBar>
    );
}

TopBar.propTypes = {
    className: PropTypes.string,
    onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
