import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useAuth0 } from '../react-auth0-spa';

// Example of user roles: ['GUEST', 'USER', 'ADMIN'];

function AuthGuard({ roles, children }) {
  const session = useSelector((state) => state.session);
  const history = useHistory();
  const { loading, isAuthenticated } = useAuth0();

  useEffect(() => {

    const routeToLogin = () => history.push('/auth/login');
    if (!session.loggedIn || !session.user || !session.token) {
      routeToLogin();
      return;
    }

    // Auth0 Valid Session
    if (!loading && !isAuthenticated) routeToLogin();

    // Were roles set?
    if (!roles.includes(session.user.role)) history.push('/errors/error-401')

  }, [history, roles, session, isAuthenticated, loading]);

  return <>{children}</>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.array.isRequired
};

export default AuthGuard;
