import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Card,
	Typography,
	CardContent,
	CardHeader,
	Divider,
	CircularProgress,
	Grid,
	colors,
	Box,
	Button
} from '@material-ui/core';
import _ from 'lodash';
import budgetActions from 'src/redux/actions/budgetActions';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgressWithLabel from '../../components/CircularProgressWithLabel';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';

const { getBudgetHealth } = budgetActions;


const useStyles = makeStyles((theme) => ({
	root: {},
	value: {
		fontWeight: theme.typography.fontWeightMedium
	},
	type: {
		fontWeight: theme.typography.fontWeightMedium
	},
	badHealth: {
		color: theme.palette.error.main
	},
	okayHealth: {
		color: colors.yellow[600]
	},
	goodHealth: {
		color: colors.green[600]
	},

}));


function BudgetHealth({ className, ...rest }) {
	const classes = useStyles();

	const levels = {
		GOOD: 'GOOD',
		OKAY: 'OKAY',
		BAD: 'BAD'
	}

	const dispatch = useDispatch();
	const health = useSelector(state => state.budget.health);
	let healthLevel = levels.BAD;

	useEffect(() => {
		dispatch(getBudgetHealth());
	}, []); // eslint-disable-line react-hooks/exhaustive-deps


	if (_.isEmpty(health)) return null;



	if (health.score > 95) {
		healthLevel = levels.GOOD;
	} else if (health.score > 65) {
		healthLevel = levels.OKAY;
	} else {
		healthLevel = levels.BAD;
	}

	let progressClassName;

	switch (healthLevel) {
		case levels.OKAY:
			progressClassName = classes.okayHealth;
			break;

		case levels.GOOD:
			progressClassName = classes.goodHealth;
			break;

		case levels.BAD:
			progressClassName = classes.badHealth;
			break;

		default:
			progressClassName = classes.badHealth;
	}




	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<CardHeader
				title="BudgetHealth&reg;"
			/>
			<Divider />
			<CardContent>

				{(!health) && <CircularProgress />}
				{
					health &&
					<React.Fragment>
						<Typography variant="caption">
							This algorithm factors in your PiggyBank value, payment delinquencies, and variance between budgeted and final amounts.
						</Typography>
						<br /><br />
						<Grid
							container
							spacing={0}
							direction="column"
							alignItems="center"
							justify="center"
							style={{ minHeight: '100' }}
						>

							<Grid item xs={12}>
								<CircularProgressWithLabel size={150} value={health.score} className={progressClassName} />

							</Grid>
							<Grid item xs={12}>
								{healthLevel === levels.GOOD && <Typography variant="subtitle1">You're doing great!</Typography>}
								{healthLevel === levels.OKAY && <Typography variant="subtitle1">Almost there!</Typography>}
								{healthLevel === levels.BAD && <Typography variant="subtitle1">Let's get you back on track.</Typography>}
							</Grid>

						</Grid>
					</React.Fragment>
				}
			</CardContent>
			{health.score < 100 &&
				<Box
					p={2}
					display="flex"
					justifyContent="flex-end"
				>
					<Button
						component={RouterLink}
						size="small"
						to="/health"
					>
						Details
          			<NavigateNextIcon className={classes.navigateNextIcon} />
					</Button>
				</Box>
			}
		</Card>
	);
}

BudgetHealth.propTypes = {
	className: PropTypes.string
};

export default BudgetHealth;
