import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import Page from 'src/components/Page';
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import PaymentList from './PaymentList';
import { useHistory } from 'react-router';
import PaymentOverview from './PaymentOverview';
import Header from './Header';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
		backgroundColor: theme.palette.background.dark,
		minHeight: '100%'
	},
	statistics: {
		marginTop: theme.spacing(3)
	},
	notifications: {
		marginTop: theme.spacing(6)
	},
	projects: {
		marginTop: theme.spacing(6)
	},
	todos: {
		marginTop: theme.spacing(6)
	}
}));

function PaymentTableContainer(props) {
	const history = useHistory();

	const { paymentData } = props;
	if (!paymentData || !paymentData.payments) {
		return null;
	}

	const routeToPaymentEdit = () => {
		history.push(`/payment-wizard/${paymentData._id}`);
	}

	const routeToLogExpense = () => {
		history.push(`/log-expense/${paymentData._id}`);
	}

	return (
		paymentData.payments.finalAmount && !props.editView ?
			(
				<div>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Header data={paymentData} handleEdit={routeToPaymentEdit} handleLogExpense={routeToLogExpense} />
						</Grid>
						<Grid item xs={12}>
							<PaymentOverview data={paymentData}></PaymentOverview>
						</Grid>
						<Grid item xs={12}>
							<PaymentList paymentData={paymentData.payments} lineItem={paymentData} />
						</Grid>
					</Grid>
				</div>
			) : <Typography>FIXME. You should not see this screen.</Typography>

	);
}

const PaymentDetails = () => {
	const classes = useStyles();
	const [editView, setEditView] = React.useState(false);
	const { lid } = useParams();
	const budgetData = useSelector(state => state.budget.result);
	const [paymentData, setPaymentData] = React.useState(null);

	useEffect(() => {
		if (!budgetData) return;
		setPaymentData(budgetData.line_items.find(li => li._id === lid));
	}, [budgetData, lid])

	return (
		<Page
			className={classes.root}
			title="Overview"
		>
			<Container maxWidth="lg">
				<PaymentTableContainer
					paymentData={paymentData}
					editView={editView}
					setEditView={setEditView} />
			</Container>
		</Page>
	);
}

export default PaymentDetails;