import React from 'react';
import {
	Typography,
	Grid,
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
	DialogTitle,
	TextField
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';
import budgetActions from 'src/redux/actions/budgetActions';
import InlineEdit from '../InlineEdit';

const { deleteCategory, renameCategory } = budgetActions;

function Header({
	currentCategory,
	handleResetSelectedCategory,
	handleChangeCategory }) {

	const dispatch = useDispatch()
	const [showDeleteCategory, setShowDeleteCategory] = React.useState(false);
	const [categoryToDelete, setCategoryToDelete] = React.useState('');

	const handleDeleteCategory = () => {
		setCategoryToDelete(currentCategory.name);
		setShowDeleteCategory(true);
	}

	const handleClose = (shouldDelete) => {
		setShowDeleteCategory(false);
		if (shouldDelete) dispatch(deleteCategory({ category: categoryToDelete }));
		setCategoryToDelete('');
		handleResetSelectedCategory();
	};

	const customValidateText = (text) => {
		return (text.length > 0 && text.length < 64);
	};

	const categoryNameChanged = (data) => {
		dispatch(renameCategory({ category: data.message, previousCategory: currentCategory.name }))
			.then(_ => {
				handleChangeCategory(data.message);
			})
	}

	return (
		<Grid
			alignItems="flex-end"
			container
			justify="space-between"
			spacing={3}
		>
			<Grid item>

				{currentCategory.name && <InlineEdit
					validate={customValidateText}
					activeClassName="editing"
					text={currentCategory.name}
					paramName="message"
					staticElement={Typography}
					editingElement={TextField}
					variant="h4"
					component="h1"
					color="textPrimary"
					change={categoryNameChanged}
				/>
				}

			</Grid>
			<Grid item>
				<Button
					color="primary"
					variant="contained"
					onClick={handleDeleteCategory}
					id="deleteCategoryBtn"
					startIcon={<DeleteIcon />}
				>
					Delete category
		  		</Button>
			</Grid>
			<Dialog
				open={showDeleteCategory}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{"Remove this category?"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Remove this category from your budget? <br /><br />This will remove all the line items within this category.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose(false)} color="primary">
						No
					</Button>
					<Button onClick={() => handleClose(true)} color="primary" autoFocus id="deleteOkBtn">
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</Grid>
	)
}
export default Header;