import React, { useEffect } from 'react';
import clsx from 'clsx';
import { connect, useDispatch, useSelector } from 'react-redux';
import budgetActions from 'src/redux/actions/budgetActions';
import CategoryTableView from './CategoryTable';
import _ from 'lodash';
import CreateCategoryFormComponent from './CreateCategoryForm';
import {
	Paper,
	Grid,
	List,
	ListItem,
	ListItemText,
	useMediaQuery,
	useTheme,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Typography,
	Hidden,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CategoryOverview from './CategoryOverview';
import Joyride, { EVENTS } from 'react-joyride';
import Header from './Header';
import { useHistory } from 'react-router';

const { loadBudget, saveBudgetLineItem, deleteBudgetLineItem, selectCategory, resetCurrentCategory } = budgetActions;

const useStyles = makeStyles((theme) => ({
	root: {
		// paddingTop: theme.spacing(3),
		// paddingBottom: theme.spacing(3)
	},
	grid: {
	},
	categoryList: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3)
	},
	categorySelectMobileContainer: {
		padding: theme.spacing(1)
	}
}));

// @TODO move to utility
function slugify(text) {
	return text.replace(/[^-a-zA-Z0-9\s+]+/ig, '').replace(/\s+/gi, "-").toLowerCase();
}


const BudgetComponent = (props) => {


	const dispatch = useDispatch();
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const [runTutorial, setRunTutorial] = React.useState(false);
	const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
	const { categoryData, loading, currentCategory, selectCategory, selectedCategory } = props;
	const allCategories = Object.keys(categoryData);
	const budget = useSelector(state => state.budget);

	const commonSteps = [
		{
			content: <Typography>We've generated a recommended budget for each category. You can change these at any time.</Typography>,
			placement: 'bottom',
			target: '#line-item-row-0 > td:nth-child(2)',
			disableBeacon: true
		},
		{
			content: <Typography>Final reflects money you've already spent on this item, and any future scheduled payments.</Typography>,
			placement: 'bottom',
			target: '#line-item-row-0 > td:nth-child(3)'
		},
	]

	const stepsDesktop = [
		...commonSteps,
		{
			content: <Typography>Money that hasn't been spent or allocated can easily be moved to your PiggyBank or to another item.</Typography>,
			placement: 'bottom',
			target: '#line-item-row-0 > td:nth-child(4)'
		},
		{
			content: <Typography>Payment tracking is the heart of any budget. Use this to track spending or enter scheduled payments.</Typography>,
			placement: 'bottom',
			target: '[data-cy=payments-btn]'
		},
	]

	const stepsMobile = [
		...commonSteps,
		{
			content: <Typography>Clicking this button gives you access to more options</Typography>,
			placement: 'left',
			target: '#line-item-row-0 > td:nth-child(4)'
		}
	]


	const handleJoyrideCallback = data => {
		const { type } = data;

		if (type === EVENTS.TOUR_END) {
			dispatch(budgetActions.completeSetup({
				weddingCity: budget.result.weddingCity,
				myName: budget.result.myName,
				partnerName: budget.result.partnerName,
				weddingDate: budget.result.weddingDate,
				theme: budget.result.theme,
				seedBudget: false,
				knownBudget: budget.result.knownBudget,
				budgetAmount: budget.result.budgetAmount,
				tourComplete: true
			}))
		}
	};


	const updateLineItem = _.debounce((text) => {
		dispatch(saveBudgetLineItem(text)).then(() => {
			props.selectCategory(currentCategory.name)
		})
	}, 1000);

	const handleChangeCategory = (category) => {
		history.push('/budget/detail/' + slugify(category));
		props.selectCategory(category);
	}

	const resetSelectedCategory = () => {
		handleChangeCategory(allCategories[0]);
	}

	const handleDeleteLineItem = (category) => {
		props.selectCategory(category);
	}

	const handleMobileCategoryChange = (event) => {
		handleChangeCategory(event.target.value);
	}

	useEffect(() => {
		if (categoryData && _.isEmpty(currentCategory) && allCategories.length > 0) {
			if (selectedCategory) {
				let categoryToSet = allCategories.filter(category => {
					return slugify(category) === selectedCategory
				})[0];
				selectCategory(categoryToSet);
			} else {
				selectCategory(allCategories[0]);
			}
		} else if (categoryData && !_.isEmpty(currentCategory)) {

		}
	}, [allCategories, categoryData, currentCategory, selectedCategory, selectCategory])

	useEffect(() => {
		if (!budget.result.tourComplete && !_.isEmpty(currentCategory))
			setRunTutorial(true);
	}, [budget.result.tourComplete, currentCategory]);

	// // Reset the selected category to refresh data when reloading view
	useEffect(() => {
		if (selectedCategory) {
			let categoryToSet = allCategories.filter(category => {
				return slugify(category) === selectedCategory
			})[0];
			selectCategory(categoryToSet);
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<React.Fragment>
			<Hidden smDown>
				<Joyride
					callback={handleJoyrideCallback}
					continuous={true}
					// getHelpers={this.getHelpers}
					run={runTutorial}
					showProgress={true}
					showSkipButton={true}
					steps={stepsDesktop}
					styles={{
						options: {
							zIndex: 10000,
							primaryColor: theme.palette.primary.main
						},
					}}
				/>
			</Hidden>
			<Hidden mdUp>
				<Joyride
					callback={handleJoyrideCallback}
					continuous={true}
					// getHelpers={this.getHelpers}
					run={runTutorial}
					showProgress={true}
					showSkipButton={true}
					steps={stepsMobile}
					styles={{
						options: {
							zIndex: 10000,
							primaryColor: theme.palette.primary.main
						},
					}}
				/>
			</Hidden>

			<Grid
				className={classes.grid}
				container
				spacing={2}
			>
				<Grid item md={2}>
					{
						(!mobileDevice && allCategories.length > 0) &&
						<div id="categoryContainer">

							<Paper className={clsx(classes.root, props.className)} >
								<List disablePadding>
									{allCategories.map(c =>
										<ListItem
											divider
											data-cy={`select-category-btn-${c}`}
											button
											key={c}
											selected={props.selectedCategory === slugify(c)}
											onClick={() => handleChangeCategory(c)}>
											<ListItemText primary={c} /></ListItem>)}
								</List>
							</Paper>

							<CreateCategoryFormComponent handleChangeCategory={handleChangeCategory} />
						</div>
					}
				</Grid>
				{mobileDevice && <Grid item xs={12}>
					<div className={classes.categorySelectMobileContainer}>
						<FormControl variant="outlined" fullWidth >
							<InputLabel id="category-select-mobile-label">Category</InputLabel>
							<Select
								id="categorySelectMobile"
								labelId="category-select-mobile-label"
								value={currentCategory.name}
								onChange={handleMobileCategoryChange}
								label="Category"
								name="categorySelectMobile"
							>
								{allCategories.map(c => <MenuItem value={c}>{c}</MenuItem>)}
							</Select>
						</FormControl>
					</div>

				</Grid>
				}
				<Grid item md={10} xs={12}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Header currentCategory={currentCategory} handleResetSelectedCategory={resetSelectedCategory} handleChangeCategory={handleChangeCategory} />
						</Grid>
						<Grid item xs={12}>
							{(currentCategory && currentCategory.data) &&
								<CategoryOverview budgetData={currentCategory.data} />
							}
						</Grid>
						<Grid item xs={12}>
							<CategoryTableView
								loading={loading}
								categoryName={currentCategory.name}
								key={currentCategory.name}
								updateLineItem={updateLineItem}
								onDeleteLineItem={handleDeleteLineItem}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}

function mapStateToProps(state) {
	const { token } = state.session;
	const budgetData = state.budget.result;
	const { categoryData, error, errorMessage, loading, currentCategory } = state.budget;
	return { idToken: token, budgetData, categoryData, error, errorMessage, loading, currentCategory };
}
export default connect(
	mapStateToProps,
	{
		loadBudget,
		saveBudgetLineItem,
		deleteBudgetLineItem,
		selectCategory,
		resetCurrentCategory
	}
)(BudgetComponent);
