import { applyMiddleware, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
// import { createLogger } from 'redux-logger';
import rootReducer from 'src/redux/reducers';
import {
  persistStore,
  persistReducer,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// const loggerMiddleware = createLogger();

export default function configureStore(preloadedState = {}) {
  const persistConfig = {
    key: 'root',
    storage,
  };

  const middlewares = [thunkMiddleware]; // loggerMiddleware
  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares)
  );

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);

  const store = createStore(persistReducer(persistConfig, rootReducer), preloadedState, composedEnhancers);

  store.__PERSISTOR = persistStore(store);

  return store;
}
