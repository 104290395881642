import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

function NotesDialog({ open, close, lineItem }) {

	const [text, setText] = React.useState("");

	useEffect(() => {
		if (lineItem.notes) setText(lineItem.notes);
	}, [lineItem]);

	const handleClose = () => {
		close();
	};

	return (
		<div>
			<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={'sm'} fullWidth>
				<DialogTitle id="form-dialog-title">Notes</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{lineItem.itemName}
					</DialogContentText>
					<TextField
						id="outlined-textarea"
						multiline
						rows={4}
						value={text}
						onChange={event => { setText(event.target.value) }}
						fullWidth
						variant="outlined"
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => close(false)} color="primary" id="notesCancelBtn">
						Cancel
		  			</Button>
					<Button onClick={() => close(true, text)} color="primary" id="notesSaveBtn">
						Save
		  			</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

NotesDialog.propTypes = {
	lineItem: PropTypes.object,
	open: PropTypes.bool.isRequired
};

export default NotesDialog;


