import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Container, Grid, Typography, useTheme, useMediaQuery, Hidden } from '@material-ui/core';
import Page from 'src/components/Page';
import Header from './Header';
import Statistics from './Statistics';
import Notifications from './Notifications';
import UpcomingPayments from './UpcomingPayments';
import BudgetHealth from './BudgetHealth';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import Joyride, { EVENTS, ACTIONS } from 'react-joyride';
import budgetActions from 'src/redux/actions/budgetActions';
import PiggyBank from './PiggyBank';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
		backgroundColor: theme.palette.background.dark,
	},
	statistics: {
		marginTop: theme.spacing(3)
	},
	notifications: {
		marginTop: theme.spacing(3)
	},
	todos: {
		marginTop: theme.spacing(3),
		minHeight: 350
	}
}));

function Dashboard() {
	const theme = useTheme();
	const classes = useStyles();
	const dispatch = useDispatch();
	const budget = useSelector(state => state.budget);
	const [runTutorial, setRunTutorial] = useState(false);
	const [stepIndex, setStepIndex] = useState(0);
	const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));


	const sharedSteps = [
		{
			content: <div>
				<Typography variant="h4" component="h1">Welcome aboard!</Typography><br />
				<Typography>This short guide will help you make the most out of weddingbudget.io</Typography>
			</div>,
			placement: 'center',
			target: 'body',
		},
		{
			content: <Typography>This is your current budget. As you update your budget, this number will reflect how you're tracking against your budget goal.</Typography>,
			target: '#originalBudgetContainer',
		},
		{
			content: <Typography>Three easy steps to get started with your budget.</Typography>,
			placement: 'bottom',
			target: '#notificationsContainer',
		}
	]
	const stepsDesktop = [
		...sharedSteps,
		{
			content: (
				<div>
					<Typography>Let's continue on to the budget screen</Typography>
				</div>
			),
			disableBeacon: true,
			disableOverlayClose: true,
			hideCloseButton: true,
			hideFooter: true,
			spotlightClicks: true,
			placement: 'top',
			target: '[data-cy=nav-btn-Budget]',
		}
	];

	const stepsMobile = [
		...sharedSteps,
		{
			content: (
				<div>
					<Typography>Let's continue on to the budget screen</Typography>
				</div>
			),
			disableBeacon: true,
			disableOverlayClose: true,
			hideCloseButton: true,
			hideFooter: true,
			spotlightClicks: true,
			placement: 'top',
			target: '[data-cy=nav-btn-Budget]',
		}
	]

	useEffect(() => {
		if (_.isEmpty(budget.result)) return;

		if (!budget.result.tourComplete) {
			setRunTutorial(true);
		}
	}, [budget.result.tourComplete]); // eslint-disable-line react-hooks/exhaustive-deps

	if (_.isEmpty(budget.result))
		return null;

	const handleJoyrideCallback = data => {
		const { type, index, action } = data;

		if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
			// Update state to advance the tour
			setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
		}

		if (mobileDevice) {
			if (type === EVENTS.STEP_AFTER && index === 2) {
				document.getElementById('mobileNavButton').click();
				setRunTutorial(false);
				setTimeout(() => {
					setRunTutorial(true);
				}, 500)
			}
		}

		if (type === EVENTS.TOUR_END) {
			dispatch(budgetActions.completeSetup({
				weddingCity: budget.result.weddingCity,
				myName: budget.result.myName,
				partnerName: budget.result.partnerName,
				weddingDate: budget.result.weddingDate,
				theme: budget.result.theme,
				seedBudget: false,
				knownBudget: budget.result.knownBudget,
				budgetAmount: budget.result.budgetAmount,
				tourComplete: true
			}))
		}
	};

	return (
		<Page
			className={classes.root}
			title="Dashboard | weddingbudget.io"
		>
			<Hidden mdDown>
				<Joyride
					callback={handleJoyrideCallback}
					continuous={true}
					// getHelpers={this.getHelpers}
					run={runTutorial}
					scrollToFirstStep={true}
					showProgress={true}
					showSkipButton={true}
					steps={stepsDesktop}
					stepIndex={stepIndex}
					styles={{
						options: {
							zIndex: 10000,
							primaryColor: theme.palette.primary.main
						},
					}}
				/>
			</Hidden>
			<Hidden lgUp>
			<Joyride
					callback={handleJoyrideCallback}
					continuous={true}
					// getHelpers={this.getHelpers}
					run={runTutorial}
					scrollToFirstStep={true}
					showProgress={true}
					showSkipButton={true}
					steps={stepsMobile}
					stepIndex={stepIndex}
					styles={{
						options: {
							zIndex: 10000,
							primaryColor: theme.palette.primary.main
						},
					}}
				/>
			</Hidden>

			<Container maxWidth="lg">
				<Header />
				<Statistics className={classes.statistics} />
				<Notifications id="notificationsContainer" className={classes.notifications} />
				{/* <Projects className={classes.projects} /> */}
				<Grid container spacing={3}>
					<Grid item sm={6}>
						<BudgetHealth className={classes.todos} />
					</Grid>
					<Grid item sm={6}>
						<PiggyBank className={classes.todos} />
					</Grid>
					<Grid item sm={6} xs={12}>
						<UpcomingPayments className={classes.todos} />
					</Grid>
				</Grid>
			</Container>
		</Page>
	);
}

export default Dashboard;
