import React from 'react';
import { connect } from 'react-redux';
import budgetActions from 'src/redux/actions/budgetActions';
import { Button, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const { createCategory, loadBudget, selectCategory } = budgetActions;

const CollectionCreateForm = (props) => {
    const { open, handleClose, handleOk } = props;

    const [categoryName, setCategoryName] = React.useState("");

    const handleCancel = () => {
        setCategoryName("");
        handleClose();
    }

    const handleAdd = () => {
        handleOk(categoryName);
        setCategoryName("");
    }

    return (

        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Add a new category"}</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    label="Name"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                    id="newCategoryName" />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Cancel
          </Button>
                <Button
                    onClick={handleAdd}
                    color="primary"
                    autoFocus
                    disabled={!categoryName}
                    id="addBtn">
                    Add
          </Button>
            </DialogActions>
        </Dialog>


    );
};

const CreateCategoryFormComponent = (props) => {

    const [open, setOpen] = React.useState(false);

    const handleCancel = () => {
        setOpen(false);
    };

    const handleCreate = (category) => {
        setOpen(false);
        props.createCategory({ category })
            .then(() => props.loadBudget())
            .then(() => props.handleChangeCategory(category));
        
    }

    return (
        <div style={{ width: "100%", marginTop: "10px" }}>
            <Button variant="contained" color="primary"
                onClick={() => setOpen(true)}
                style={{ width: "100%" }}
                id="createCategoryBtn">
                Add Category
            </Button>
            <CollectionCreateForm
                handleClose={(handleCancel)}
                handleOk={handleCreate}
                open={open}
            />
        </div>
    );
}
export default connect(undefined, {
    createCategory,
    loadBudget,
    selectCategory
})(CreateCategoryFormComponent);