import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { EditableTextCell, EditableNumberCell } from './HelperCells';
import { useHistory } from 'react-router-dom';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	LinearProgress,
	colors,
	useMediaQuery,
	IconButton,
	Collapse,
	Box,
} from '@material-ui/core';
import NotesDialog from './NotesDialog';
import { useDispatch } from 'react-redux';
import budgetActions from 'src/redux/actions/budgetActions';
import LineItemActionMenu from './LineItemActionMenu';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { FormattedNumber } from 'react-intl';
import PaymentIcon from '@material-ui/icons/Payment';
import AvailableFundsDialog from './AvailableFundsDialog';

const { saveBudgetLineItem, selectCategory } = budgetActions;


const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
	draftRow: {
		backgroundColor: colors.grey[100]
	},
	denseFont: {
		fontSize: 12
	}
});

const useRowStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
	mobileRow: {
		padding: 6
	},
	denseFont: {
		fontSize: 12
	},
	actionButton: {
		flex: '1 1 auto',
		margin: theme.spacing(1)
	}
}));

function MUITable(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const theme = useTheme();
	const history = useHistory();
	const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
	const [showDeleteLineItem, setShowDeleteLineItem] = React.useState(false);
	const [recordToDelete, setRecordToDelete] = React.useState({});
	const [notesRecord, setNotesRecord] = React.useState({});
	const [showNotesDialog, setShowNotesDialog] = React.useState(false);
	const [showAvailableFundsDialog, setShowAvailableFundsDialog] = React.useState(false);

	const handleConfirmDelete = (record) => {
		setRecordToDelete(record);
		setShowDeleteLineItem(true);
	}

	const handleClose = action => {
		if (action) onDeleteItem(recordToDelete);
		setShowDeleteLineItem(false);
	}

	const onDeleteItem = record => {
		if (props.onDeleteItem) props.onDeleteItem(record);
	}

	const openNotes = (record) => {
		setNotesRecord(record);
		setShowNotesDialog(true);
	}

	const closeNotesDialog = (save, text) => {
		setShowNotesDialog(false);

		// fire save call
		if (save) {
			notesRecord.notes = text;
			dispatch(saveBudgetLineItem(notesRecord));
			setNotesRecord({});
		}

	}

	const openAvailableFundsDialog = (row) => {
		setNotesRecord(row);
		setShowAvailableFundsDialog(true);
	}

	const closeAvailableFundsDialog = async (save, moveAmount, moveToLineItem, originalLineItem) => {
		setShowAvailableFundsDialog(false);

		if (save) {
			setNotesRecord({});


			if (moveAmount > 0) {
				// Move excess funds to another line item

				// Update original line item 
				const updatedOriginalLineItem = { ...originalLineItem };
				updatedOriginalLineItem.budgeted = parseFloat(updatedOriginalLineItem.budgeted - moveAmount);
				await dispatch(saveBudgetLineItem(updatedOriginalLineItem));

				if (moveToLineItem !== 'piggy_bank') {
					// Update move to line item
					const updatedNewLineItem = { ...moveToLineItem };
					updatedNewLineItem.budgeted += parseFloat(moveAmount);
					await dispatch(saveBudgetLineItem(updatedNewLineItem));
				}
			} else {
				// Cover overspending with budget from another line item

				// Add the available funds from the moveToLineItem to the originalLineItem
				// Update original line item 
				const availableFundsCalc = parseFloat(moveToLineItem.budgeted - moveToLineItem.actual) - moveAmount;
				let availableFunds = 0;
				if (availableFundsCalc > moveAmount && availableFundsCalc > 0) {
					availableFunds = Math.abs(moveAmount);
				}

				const updatedOriginalLineItem = { ...originalLineItem };
				updatedOriginalLineItem.budgeted = parseFloat(updatedOriginalLineItem.budgeted + availableFunds);
				await dispatch(saveBudgetLineItem(updatedOriginalLineItem));

				if (moveToLineItem !== 'piggy_bank') {
					// Update move to line item
					const updatedNewLineItem = { ...moveToLineItem };
					updatedNewLineItem.budgeted -= parseFloat(availableFunds);
					await dispatch(saveBudgetLineItem(updatedNewLineItem));
				}
			}

			dispatch(selectCategory(originalLineItem.category));
		}
	}

	const routeToPayments = (lineItem) => {

		if (lineItem.payments.expenses.length === 0 && lineItem.payments.paymentSchedule.length === 0) {
			// route to wizard
			history.push(`/payment-wizard/${lineItem._id}`);
		} else {
			// route to payment details
			history.push(`/payments/${lineItem._id}`)
		}
	}

	return (
		<TableContainer component={Paper}>
			{!mobileDevice &&
				<Table className={classes.table} size="small" aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell style={{ minWidth: 380 }}>Name</TableCell>
							<TableCell>Budgeted</TableCell>
							<TableCell>Final</TableCell>
							<TableCell>Available</TableCell>
							<TableCell align="center">Actions</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						<TableRow>
							<TableCell style={{ padding: 0 }} colSpan={5}>
								{props.loading && <LinearProgress variant="query" />}
							</TableCell>
						</TableRow>
						{props.dataSource && props.dataSource.map((row, index) => (
							<TableRow id={`line-item-row-${index}`} key={index} className={row.new ? classes.draftRow : null}>
								<TableCell component="th" scope="row">
									<EditableTextCell
										key={`${row._id}_itemName`}
										useKey={`${row._id}_itemName`}
										index={index}
										columnsKey="itemName"
										value={row.itemName}
										onChange={(value, columnsKey, index) => props.onCellChange(value, columnsKey, index)}
									/>
								</TableCell>
								<TableCell>
									<EditableNumberCell
										key={`${row._id}_budgeted`}
										index={index}
										columnsKey="budgeted"
										value={row.budgeted}
										onChange={(value, columnsKey, index) => props.onCellChange(value, columnsKey, index)}
									/>
								</TableCell>
								<TableCell>
									<FormattedNumber style={`currency`} currency="USD" value={row.actual} />
								</TableCell>
								<TableCell>
									<Button onClick={() => { openAvailableFundsDialog(row) }}><FormattedNumber style={`currency`} currency="USD" value={row.budgeted - row.actual} /></Button>
								</TableCell>
								<TableCell align="center">
									{!row.new &&
										<span style={{ display: 'inline-flex' }}>
											<IconButton onClick={() => routeToPayments(row)} aria-label="payments" title="Payments" data-cy="payments-btn" disabled={props.loading}>
												<PaymentIcon />
											</IconButton>
											<LineItemActionMenu
												disabled={props.loading}
												onNotes={() => { openNotes(row) }}
												onDelete={() => { handleConfirmDelete(row) }}></LineItemActionMenu>
										</span>}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			}

			{mobileDevice &&
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell style={{ minWidth: 125 }}>Name</TableCell>
							<TableCell className={classes.denseFont}>Budgeted</TableCell>
							<TableCell className={classes.denseFont}>Final</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>

					<TableBody>
						<TableRow>
							<TableCell style={{ padding: 0 }} colSpan={4}>
								{props.loading && <LinearProgress variant="query" />}
							</TableCell>
						</TableRow>
						{props.dataSource && props.dataSource.map((row, index) => (
							<MobileRow row={row} index={index}
								onCellChange={props.onCellChange}
								openNotes={openNotes}
								openAvailableFundsDialog={openAvailableFundsDialog}
								handleConfirmDelete={handleConfirmDelete}
								routeToPayments={routeToPayments} />
						))}
					</TableBody>

				</Table>
			}

			<Dialog
				open={showDeleteLineItem}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{"Delete item"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Remove {recordToDelete.itemName} from your budget? <br /><br />This will remove all data within this line item, including payment schedules.
						<br /><br /><strong>This action cannot be undone.</strong>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose(false)} color="primary" id="deleteLineItemNoBtn">
						No
		  		</Button>
					<Button onClick={() => handleClose(true)} color="primary" autoFocus id="deleteLineItemYesBtn">
						Yes
		  		</Button>
				</DialogActions>
			</Dialog>

			<NotesDialog
				lineItem={notesRecord}
				open={showNotesDialog}
				close={closeNotesDialog} />

			<AvailableFundsDialog
				lineItem={notesRecord}
				open={showAvailableFundsDialog}
				close={closeAvailableFundsDialog} />



		</TableContainer>
	);
}

function MobileRow(props) {
	const { row, index, routeToPayments } = props;
	const [open, setOpen] = React.useState(false);
	const classes = useRowStyles();

	return (
		<React.Fragment>
			<TableRow id={`line-item-row-${index}`} key={index} className={row.new ? classes.draftRow : null}>

				<TableCell component="th" scope="row" className={classes.mobileRow}>
					<EditableTextCell
						key={`${row._id}_itemName`}
						useKey={`${row._id}_itemName`}
						index={index}
						columnsKey="itemName"
						value={row.itemName}
						margin="dense"
						inputClassName={classes.denseFont}
						onChange={(value, columnsKey, index) => props.onCellChange(value, columnsKey, index)}
					/>
				</TableCell>
				<TableCell className={classes.mobileRow}>
					<EditableNumberCell
						key={`${row._id}_budgeted`}
						index={index}
						columnsKey="budgeted"
						value={row.budgeted}
						margin="dense"
						inputClassName={classes.denseFont}
						onChange={(value, columnsKey, index) => props.onCellChange(value, columnsKey, index)}
					/>
				</TableCell>
				<TableCell className={classes.mobileRow}>
					<FormattedNumber style={`currency`} currency="USD" value={row.actual} />
				</TableCell>
				<TableCell style={{ width: 15, padding: 2 }} align="center" >
					<IconButton aria-label="expand row" size="medium" onClick={() => setOpen(!open)} style={{ padding: 0 }}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>

			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1} style={{ width: '100%' }}>
							{!row.new &&
								<React.Fragment>
									<Button
										className={classes.actionButton}
										variant="contained"
										size="small"
										data-cy={`action-available-btn-${index}`}
										onClick={() => props.openAvailableFundsDialog(row)}>
										Available: <FormattedNumber style={`currency`} currency="USD" value={row.budgeted - row.actual} />
									</Button>
									<Button
										className={classes.actionButton}
										variant="contained"
										size="small"
										data-cy={`action-payments-btn-${index}`}
										onClick={() => routeToPayments(row)}>
										Payments
									</Button>
									<Button
										className={classes.actionButton}
										onClick={() => props.openNotes(row)}
										variant="contained"
										size="small">
										Notes
									</Button>
									<Button
										onClick={() => props.handleConfirmDelete(row)}
										className={classes.actionButton}
										variant="contained"
										size="small">
										Delete
									</Button>
								</React.Fragment>
							}
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	)
}

MobileRow.propTypes = {
	openNotes: PropTypes.func.isRequired,
	handleConfirmDelete: PropTypes.func.isRequired,
	onCellChange: PropTypes.func.isRequired
}

MUITable.propTypes = {
	onDeleteItem: PropTypes.func.isRequired,
	onCellChange: PropTypes.func.isRequired
};

export default MUITable;