import { combineReducers } from 'redux';
import sessionReducer from './sessionReducer';
import budgetReducer from './budgetReducer';

const rootReducer = combineReducers({
  session: sessionReducer,
  budget: budgetReducer
});

export default rootReducer;
