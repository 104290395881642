import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import {
    Typography,
    Grid,
    colors,
    Breadcrumbs,
    Link,
    Button
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useSelector } from 'react-redux';
import { FormattedNumber } from 'react-intl';


const useStyles = makeStyles((theme) => ({
    root: {},
    toolbar: {
        '& > * + *': {
            marginLeft: theme.spacing(1)
        }
    },
    deleteButton: {
        color: theme.palette.common.white,
        backgroundColor: colors.red[600],
        '&:hover': {
            backgroundColor: colors.red[900]
        }
    },
    deleteIcon: {
        marginRight: theme.spacing(1)
    }
}));

function Header({
    order,
    onChangeView,
    categorySlug,
    currentView,
    className, ...rest }) {
    const classes = useStyles();
    const budgetData = useSelector(state => state.budget.result);

    let piggyBank = 0;

    if (budgetData && budgetData.line_items) {
        const budgetedTotal = budgetData.line_items
            .map(i => i.budgeted)
            .reduce((a, b) => a + b, 0);

        piggyBank = budgetData.budgetAmount - budgetedTotal;
    }

    return (
        <div
            className={clsx(classes.root, className)}
        >
            <Grid
                alignItems="flex-end"
                container
                justify="space-between"
                spacing={3}
            >
                <Grid item>

                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                        gutterBottom
                    >

                        <Typography
                            variant="overline"
                        >
                            Budget
                        </Typography>
                        <Link
                            variant="body1"
                            color="inherit"
                            to="/budget"
                            component={RouterLink}
                        >
                            Overview
                        </Link>
                    </Breadcrumbs>
                    <Typography
                        color="textPrimary"
                        variant="h3"
                    >
                        {categorySlug ? 'Details' : 'Overview'}
                    </Typography>
                </Grid>
                {categorySlug &&
                    <Grid item>
                        <Button size="large">PiggyBank: <FormattedNumber style={`currency`} currency="USD" value={piggyBank} /></Button>
                    </Grid>
                }
                {!categorySlug &&
                    <React.Fragment>
                        <Grid item>
                            <Grid
                                alignItems="flex-end"
                                container
                                justify="space-between"
                                spacing={2}
                            >
                                <Grid item>
                                    <Button size="large">PiggyBank: <FormattedNumber style={`currency`} currency="USD" value={piggyBank} /></Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }
            </Grid>
        </div>
    );
}

Header.propTypes = {
    className: PropTypes.string,
    onChangeView: PropTypes.func.isRequired,
    categorySlug: PropTypes.string,
    currentView: PropTypes.string.isRequired
};

export default Header;
