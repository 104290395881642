import axios from 'src/utils/axios';
import bugsnagClient from '../../lib/bugsnag';

const apiURL = process.env.REACT_APP_API_HOST;

const unauthorizedErrorHandler = (error, history) => {
	if (error.response && error.response.status === 401)
		history.push('/auth/login');
}

const loadBudgetRequest = (state, history) => {
	const { token } = state.session;
	return dispatch =>
		axios.get(`${apiURL}/budgets`,
			{
				headers: { 'Authorization': `Bearer ${token}` }
			})
			.then(({ data }) => dispatch(budgetActions.loadBudgetResult(data)))
			.catch(error => {
				dispatch(budgetActions.loadBudgetError(error))
				unauthorizedErrorHandler(error, history);
				throw error;
			});

}

const checkSetupRequest = (state, history) => {
	const { token } = state.session;
	return dispatch =>
		axios.get(`${apiURL}/budgets/check-setup`,
			{
				headers: { 'Authorization': `Bearer ${token}` }
			})
			.then(({ data }) => dispatch(budgetActions.checkSetupResult(data, history)))
			.catch(err => {
				unauthorizedErrorHandler(err, history);
				dispatch(budgetActions.checkSetupFailed(err, history));
			});

}

const completeSetupRequest = (state, data, history) => {
	const { token } = state.session;
	return dispatch => {
		return fetch(`${apiURL}/budgets/complete-setup`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		})
			.then(response => response.json())
			.then(json => {
				dispatch(budgetActions.loadBudget());
				return json;
			})
			.then(json => budgetActions.completeSetupResult(json, history))
	}
}

const createCategoryRequest = (state, data) => {
	const { token } = state.session;
	return dispatch => {
		return fetch(`${apiURL}/budgets/category`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		})
			.then(response => response.json())
			.then(json => dispatch(budgetActions.createCategoryResult(json)))
	}
}

const deleteCategoryRequest = (state, data) => {
	const { token } = state.session;
	return dispatch => {
		return fetch(`${apiURL}/budgets/category`, {
			method: 'DELETE',
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		})
			.then(response => response.json())
			.then(json => dispatch(budgetActions.deleteCategoryResult(json, data)))
	}
}

const saveBudgetLineItemRequest = (state, data) => {
	const { token } = state.session;
	return dispatch => {
		return fetch(`${apiURL}/budgets/update-item`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		})
			.then(response => response.json())
			.then(json => dispatch(budgetActions.saveBudgetLineItemResult(json, data)))
	}
}

const deleteBudgetLineItemRequest = (state, data) => {
	const { token } = state.session;
	return dispatch => {
		return fetch(`${apiURL}/budgets/update-item`, {
			method: 'DELETE',
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		})
			.then(response => response.json())
			.then(json => dispatch(budgetActions.deleteBudgetLineItemResult(json, data)))
	}
}

const updateHeroImageRequest = (state, formData, rawFile) => {
	const { token } = state.session;
	return dispatch => {
		return fetch(`${apiURL}/budgets/hero-image`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
				// 'Content-Type': 'multipart/form-data'
			},
			body: formData
		})
			.then(response => response.json())
			.then(json => {
				const contentType = rawFile.type;
				const options = {
					// params: {
					// 	Key: json.key,
					// 	ContentType: contentType
					// },
					headers: {
						'Content-Type': contentType
					}
				};
				return axios
					.put(json.url, rawFile, options)
					.then(res => {
						return json;
					})
					.catch(err => {
						console.log('err', err);
					});
			})
			.then(json => dispatch(budgetActions.updateHeroImageResult(json)))
	}
}

const renameCategoryRequest = (state, payload) => {
	const { token } = state.session;
	return dispatch =>
		axios({
			url: `${apiURL}/budgets/rename-category`,
			method: 'POST',
			headers: { 'Authorization': `Bearer ${token}` },
			data: payload
		})
			.then(({ data }) => dispatch(budgetActions.renameCategoryResult(data)))
			.catch(error => {
				dispatch(budgetActions.renameCategoryError(error))
				unauthorizedErrorHandler(error);
				throw error;
			});
}

const getPaymentTrackerRequest = (state) => {
	const { token } = state.session;
	return dispatch => {
		return fetch(`${apiURL}/budgets/tracker`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then(response => response.json())
			.then(json => dispatch(budgetActions.getPaymentTrackerResult(json)))
	}
}

const getBudgetHealthRequest = (state) => {
	const { token } = state.session;
	return dispatch => {
		return fetch(`${apiURL}/budgets/health`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then(response => response.json())
			.then(json => dispatch(budgetActions.getBudgetHealthResult(json)))
	}
}







const budgetActions = {
	CHECK_SETUP: 'CHECK_SETUP',
	CHECK_SETUP_RESULT: 'CHECK_SETUP_RESULT',
	CHECK_SETUP_FAILED: 'CHECK_SETUP_FAILED',
	LOAD_BUDGET: 'LOAD_BUDGET',
	LOAD_BUDGET_RESULT: 'LOAD_BUDGET_RESULT',
	LOAD_BUDGET_ERROR: 'LOAD_BUDGET_ERROR',
	SAVE_BUDGET_LINE_ITEM: 'SAVE_BUDGET_LINE_ITEM',
	SELECT_CATEGORY: 'SELECT_CATEGORY',
	RESET_CURRENT_CATEGORY: 'RESET_CURRENT_CATEGORY',
	CREATE_CATEGORY: 'CREATE_CATEGORY',
	CREATE_CATEGORY_RESULT: 'CREATE_CATEGORY_RESULT',
	DELETE_CATEGORY_RESULT: 'DELETE_CATEGORY_RESULT',
	SAVE_BUDGET_LINE_ITEM_RESULT: 'SAVE_BUDGET_LINE_ITEM_RESULT',
	DELETE_BUDGET_LINE_ITEM_RESULT: 'DELETE_BUDGET_LINE_ITEM_RESULT',
	LOAD_PAYMENT_DATA: 'LOAD_PAYMENT_DATA',
	UPDATE_HERO_IMAGE_RESULT: 'UPDATE_HERO_IMAGE_RESULT',
	GET_PAYMENT_TRACKER_RESULT: 'GET_PAYMENT_TRACKER_RESULT',
	RENAME_CATEGORY: 'RENAME_CATEGORY',
	RENAME_CATEGORY_RESULT: 'RENAME_CATEGORY_RESULT',
	// GET_BUDGET_HEALTH: 'GET_BUDGET_HEALTH',
	GET_BUDGET_HEALTH_RESULT: 'GET_BUDGET_HEALTH_RESULT',



	getPaymentTracker: () => {
		return function (dispatch, getState) {
			return dispatch(getPaymentTrackerRequest(getState()));
		}
	},

	getPaymentTrackerResult: (data) => (dispatch) => dispatch({
		type: budgetActions.GET_PAYMENT_TRACKER_RESULT,
		data
	}),

	getBudgetHealth: () => {
		return function (dispatch, getState) {
			return dispatch(getBudgetHealthRequest(getState()));
		}
	},

	getBudgetHealthResult: (data) => (dispatch) => dispatch({
		type: budgetActions.GET_BUDGET_HEALTH_RESULT,
		data
	}),

	updateHeroImage: (formData, rawFile) => {
		return function (dispatch, getState) {
			return dispatch(updateHeroImageRequest(getState(), formData, rawFile));
		}
	},

	updateHeroImageResult: (data) => (dispatch) => dispatch({
		type: budgetActions.UPDATE_HERO_IMAGE_RESULT,
		data
	}),

	checkSetup: (history) => {
		return function (dispatch, getState) {
			dispatch({ type: budgetActions.CHECK_SETUP });
			return dispatch(checkSetupRequest(getState(), history));
		}
	},
	checkSetupResult: (data) => dispatch => dispatch({
		type: budgetActions.CHECK_SETUP_RESULT, data
	}),

	checkSetupFailed: (err, history) => {
		bugsnagClient.notify(new Error('Critical error calling API: /budgets/check-setup'), {
			metaData: { message: err.message },
			severity: 'error'
		});
		history.push('/errors/error-500');
		return { type: budgetActions.CHECK_SETUP_FAILED }
	},

	// Complete setup
	completeSetup: (data, history) => {
		return function (dispatch, getState) {
			return dispatch(completeSetupRequest(getState(), data, history));
		}
	},

	completeSetupResult: (data, history) => {
		if (history) history.push('/dashboard');
	},



	// Category CRUD

	// Create Category

	createCategory: (data, history) => {
		return function (dispatch, getState) {
			return dispatch(createCategoryRequest(getState(), data, history));
		}
	},

	createCategoryResult: (data) => (dispatch) => dispatch({
		type: budgetActions.CREATE_CATEGORY_RESULT,
		data
	}),

	deleteCategory: data => {
		return function (dispatch, getState) {
			return dispatch(deleteCategoryRequest(getState(), data));
		}
	},

	deleteCategoryResult: (data, request) => (dispatch) => dispatch({
		type: budgetActions.DELETE_CATEGORY_RESULT,
		data,
		request
	}),

	renameCategory: (payload) => {
		return function (dispatch, getState) {
			dispatch({ type: budgetActions.RENAME_CATEGORY });
			return dispatch(renameCategoryRequest(getState(), payload));
		}
	},

	renameCategoryResult: (data) => (dispatch) => dispatch({
		type: budgetActions.RENAME_CATEGORY_RESULT,
		data
	}),


	// Load budget

	loadBudgetResult: (data) => (dispatch) => dispatch({
		type: budgetActions.LOAD_BUDGET_RESULT,
		data
	}),
	loadBudgetError: (error) => (dispatch) => dispatch({
		type: budgetActions.LOAD_BUDGET_ERROR,
		error
	}),
	loadBudget: (history) => {
		return function (dispatch, getState) {
			// First dispatch: the app state is updated to inform
			// that the API call is starting.
			return dispatch(loadBudgetRequest(getState(), history));
		}
	},

	// Save budget

	saveBudgetLineItem: data => {
		return function (dispatch, getState) {
			dispatch({ type: budgetActions.SAVE_BUDGET_LINE_ITEM });
			return dispatch(saveBudgetLineItemRequest(getState(), data));
		}
	},

	saveBudgetLineItemResult: (data) => (dispatch) => dispatch({
		type: budgetActions.SAVE_BUDGET_LINE_ITEM_RESULT,
		data
	}),

	deleteBudgetLineItem: data => {
		return function (dispatch, getState) {
			return dispatch(deleteBudgetLineItemRequest(getState(), data));
		}
	},

	deleteBudgetLineItemResult: (data, request) => (dispatch) => dispatch({
		type: budgetActions.DELETE_BUDGET_LINE_ITEM_RESULT,
		data,
		request
	}),

	selectCategory: data => ({
		type: budgetActions.SELECT_CATEGORY,
		data
	}),
	resetCurrentCategory: () => ({ type: budgetActions.RESET_CURRENT_CATEGORY }),


	// Payments

	loadPaymentData: (id) => ({ type: budgetActions.LOAD_PAYMENT_DATA, id }),
}

export default budgetActions;
