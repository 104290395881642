import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module'
import bugsnagClient from './lib/bugsnag'
import Error500 from './views/Error500';
import { SettingsProvider } from './context/SettingsContext';
import { restoreSettings } from 'src/utils/settings';

const ErrorBoundary = bugsnagClient.getPlugin('react')


const tagManagerArgs = {
    gtmId: 'GTM-PVJ4MXHC'
}

TagManager.initialize(tagManagerArgs);

const settings = restoreSettings();

ReactDOM.render(
    <SettingsProvider settings={settings}>
        <ErrorBoundary FallbackComponent={Error500} >
            <App />
        </ErrorBoundary>
    </SettingsProvider>,
    document.getElementById("root")
);

serviceWorker.unregister();
