import axios from 'src/utils/axios';

export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const SESSION_CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const SESSION_CHANGE_PASSWORD_RESULT = 'CHANGE_PASSWORD_RESULT';
export const SESSION_CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

const apiURL = process.env.REACT_APP_API_HOST;

export const changePasswordRequest = (state, data) => {
  const { token } = state.session;
  return dispatch => {
    return axios({
      url: `${apiURL}/auth/change-password`,
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    })
      .then(data => dispatch({ type: SESSION_CHANGE_PASSWORD_RESULT, data }))
      // .catch(error => dispatch({ type: SESSION_CHANGE_PASSWORD_ERROR, error }))
  }
}


export const login = (token, user) => (dispatch) => dispatch({
  type: SESSION_LOGIN,
  token,
  user
});

export const logout = () => (dispatch) => dispatch({
  type: SESSION_LOGOUT
});

export const changePassword = (data) => (dispatch, getState) => {
  dispatch({ type: SESSION_CHANGE_PASSWORD });
  return dispatch(changePasswordRequest(getState(), data));
}



