import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Button,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/LockOutlined';
import DateFnsAdapter from "@date-io/date-fns";
import differenceInDays from "date-fns/differenceInDays";

const dateFns = new DateFnsAdapter();

function DaysLeft({
    budget,
    handleOnClick,
    classes
}) {
    const [daysRemaining, setDaysRemaining] = useState(0);
    const [trialSeverity, setTrialSeverity] = useState("");





    useEffect(() => {

        const getDaysRemaining = () => {
            let createdDate = dateFns.date(budget.result.budgetCreatedDate);
            const today = dateFns.date();
            const daysRemaining = 14 - differenceInDays(today, createdDate);
            const daysRemainingFormatted = daysRemaining >= 0 ? daysRemaining : 0;
            setDaysRemaining(daysRemainingFormatted);
        }

        getDaysRemaining();
    }, [budget]);

    useEffect(() => {
        const getTrialSeverity = () => {
            // console.debug(`Days remaining: ${daysRemaining}`);

            let severity;
            if (daysRemaining <= 1) {
                severity = "trialExpired";
            } else if (daysRemaining > 1 && daysRemaining <= 10) {
                severity = "warning";
            } else {
                severity = "info";
            }

            setTrialSeverity(severity);
        }

        getTrialSeverity();
    }, [daysRemaining])


    return (<Button
        className={clsx(classes.trialButton, classes[trialSeverity])}
        onClick={handleOnClick}
        variant="contained"
    >
        {
            daysRemaining > 0 &&
            <>
                {daysRemaining} {daysRemaining === 1 ? 'day' : 'days'} left
            </>
        }
        {
            daysRemaining === 0 &&
            <>
                <LockIcon className={classes.trialIcon} />
                TRIAL EXPIRED
            </>
        }

    </Button>)
}

DaysLeft.propTypes = {
    budget: PropTypes.object.isRequired,
    handleOnClick: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
}


export default DaysLeft;