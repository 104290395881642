import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
  Grid,
  Divider,
} from "@material-ui/core";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { FormattedNumber } from "react-intl";

function AvailableFundsDialog({ open, close, lineItem }) {
  const categoryData = useSelector((state) => state.budget.categoryData);
  const budgetData = useSelector((state) => state.budget.result);
  const allCategories = Object.keys(categoryData);

  const [selectedLineItem, setSelectedLineItem] = React.useState("");
  const [moveAmount, setMoveAmount] = React.useState(0);
  const [submitted, setSubmitted] = React.useState(false);

  const handleClose = (save) => {
    if (save) {
      setSubmitted(true);
      if (selectedLineItem === "") return;
      let newLineItem;
      if (selectedLineItem !== "piggy_bank") {
        newLineItem = budgetData.line_items.find(
          (li) => li._id === selectedLineItem
        );
      } else {
        newLineItem = "piggy_bank";
      }
      close(true, moveAmount, newLineItem, lineItem);
    } else {
      close();
    }
  };

  const budgetedTotal = budgetData.line_items
    .map((i) => i.budgeted)
    .reduce((a, b) => a + b, 0);

  useEffect(() => {
    setSubmitted(false);
    if (lineItem && lineItem.budgeted) {
      setMoveAmount(parseFloat(lineItem.budgeted - lineItem.actual));
      setSelectedLineItem("");
    }
  }, [lineItem]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="form-dialog-title"
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Move Budget</DialogTitle>
        <DialogContent>
          {moveAmount >= 0 && (
            <React.Fragment>
              <DialogContentText>Move Amount</DialogContentText>
              <CurrencyTextField
                value={moveAmount}
                minimumValue="0"
                fullWidth
                variant="outlined"
                onChange={(_, val) => setMoveAmount(val)}
              />
              <br />
              <br />
            </React.Fragment>
          )}

          <DialogContentText>
            {moveAmount >= 0 ? "To" : "Cover Overspending With"}
          </DialogContentText>
          <FormControl
            variant="outlined"
            fullWidth
            error={submitted && selectedLineItem === ""}
          >
            <InputLabel id="category-select-mobile-label">Line Item</InputLabel>
            <Select
              id="lineItemSelect"
              labelId="line-item-select-label"
              label="Line Item"
              name="lineItemSelect"
              value={selectedLineItem}
              onChange={(e) => setSelectedLineItem(e.target.value)}
            >
              <ListSubheader>PiggyBank</ListSubheader>
              <MenuItem value={"piggy_bank"}>
                <Grid container justify="space-between">
                  <Grid item>PiggyBank</Grid>
                  <Grid item>
                    <FormattedNumber
                      style={`currency`}
                      currency="USD"
                      value={budgetData.budgetAmount - budgetedTotal}
                    />
                  </Grid>
                </Grid>{" "}
              </MenuItem>

              <Divider />
              {allCategories.map((c) => {
                let children = [];

                children.push(<ListSubheader>{c}</ListSubheader>);
                categoryData[c].forEach((menuItem) => {
                  if (menuItem._id !== lineItem._id) {
                    children.push(
                      <MenuItem value={menuItem._id}>
                        <Grid container justify="space-between">
                          <Grid item>{menuItem.itemName}</Grid>
                          <Grid item>
                            <FormattedNumber
                              style={`currency`}
                              currency="USD"
                              value={menuItem.budgeted}
                            />
                          </Grid>
                        </Grid>{" "}
                      </MenuItem>
                    );
                  }
                });

                return children;
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose(false)}
            color="primary"
            id="notesCancelBtn"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleClose(true)}
            color="primary"
            id="notesSaveBtn"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AvailableFundsDialog.propTypes = {
  lineItem: PropTypes.object,
  open: PropTypes.bool.isRequired,
};

export default AvailableFundsDialog;
