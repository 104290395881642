import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import {
	Typography,
	Grid,
	Button,
	Breadcrumbs,
	Link
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Label from 'src/components/Label';

const useStyles = makeStyles((theme) => ({
	root: {},
	summaryButton: {
		backgroundColor: theme.palette.common.white
	},
	barChartIcon: {
		marginRight: theme.spacing(1)
	},
	image: {
		width: '100%',
		maxHeight: 400,
		minHeight: 400,
	},
	card: {
		display: 'flex',
	},
	labelContainer: {
		display: 'inline-flex',
		alignItems: 'center',
	},
	label: {
		margin: theme.spacing(1)
	}
}));

// @TODO move to utility
function slugify(text) {
	return text.replace(/[^-a-zA-Z0-9\s+]+/ig, '').replace(/\s+/gi, "-").toLowerCase();
}

function Header({ data, handleEdit, handleLogExpense }) {
	const classes = useStyles();

	const isContract = data.payments.paymentSchedule.length > 0;

	return (
		<div
			className={clsx(classes.root)}
		>
			<Grid
				alignItems="flex-end"
				container
				justify="space-between"
				spacing={3}
			>
				<Grid item>

					<Breadcrumbs
						separator={<NavigateNextIcon fontSize="small" />}
						aria-label="breadcrumb"
						gutterBottom
					>

						<Typography
							variant="overline"
						>
							Budget
						</Typography>
						<Link
							variant="body1"
							color="inherit"
							to={`/budget/detail/${slugify(data.category)}`}
							component={RouterLink}
						>
							{data.category}
						</Link>
						<Typography>{data.itemName}</Typography>
					</Breadcrumbs>
					<span className={classes.labelContainer}>
						<Typography
							color="textPrimary"
							variant="h3"
						>Payments
					</Typography>
						{isContract && <Label color="secondary" className={classes.label}>Contract</Label>}
					</span>
				</Grid>
				<Grid item>
					{isContract ? <Button
						color="primary"
						variant="contained"
						onClick={handleEdit}
						id="deleteCategoryBtn"
						startIcon={<EditIcon />}
					>
						Edit
						</Button>
						:
						<Button
							color="primary"
							variant="contained"
							onClick={handleLogExpense}
							id="deleteCategoryBtn"
							startIcon={<AddIcon />}
						>
							Log Expense
						</Button>
					}
				</Grid>
			</Grid>
		</div>
	);
}

Header.propTypes = {
	className: PropTypes.string,
	handleEdit: PropTypes.func.isRequired
};

function mapStateToProps(state) {
	const budgetData = state.budget.result;
	return { budgetData }
}
export default connect(mapStateToProps)(Header);
