import React, { useEffect } from 'react';
import MUITable from './MUITable';
import * as ObjectID from 'bson-objectid';
import budgetActions from 'src/redux/actions/budgetActions';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

const { deleteBudgetLineItem } = budgetActions;

function CategoryTableView({
	updateLineItem,
	onDeleteLineItem,
	loading,
	categoryName
}) {

	const dispatch = useDispatch();

	const [categoryData, setCategoryData] = React.useState(null);
	const categoryDataSource = useSelector(state => state.budget.currentCategory);

	useEffect(() => {
		if(categoryDataSource.data) setCategoryData(categoryDataSource.data);
	}, [categoryDataSource]);

	const onCellChange = (value, columnsKey, index) => {
		const newBudgetData = { ...categoryData };
		newBudgetData[index][columnsKey] = value;
		if (updateLineItem) updateLineItem(newBudgetData[index]);
	}

	const onDeleteItem = (record) => {
		dispatch(deleteBudgetLineItem(record)).then(() => {
			if (onDeleteLineItem) onDeleteLineItem(categoryName)
		});
	}

	/**
	 * Add a new line item to local state
	 */
	const handleAdd = () => {
		const newData = {
			actual: 0,
			budgeted: 0,
			category: categoryData[0].category,
			itemName: '',
			_id: ObjectID(),
			new: true
		};
		setCategoryData([...categoryData, newData]);
	};

	return (
		<div>

			<br />

			<MUITable
				dataSource={categoryData}
				onCellChange={onCellChange}
				onDeleteItem={onDeleteItem}
				loading={loading} />

			<br />

			<Button
				disabled={loading}
				onClick={handleAdd}
				variant="contained" color="primary"
				style={{ marginBottom: 16 }}
				id="addNewExpenseBtn"
			>
				Add
			</Button>
		</div>
	);

}

CategoryTableView.propTypes = {
	onDeleteLineItem: PropTypes.func.isRequired
}

export default CategoryTableView;