import React, { useEffect } from 'react';
import routes from './routes';
import CookiesNotification from './components/CookiesNotification';
import ScrollReset from './components/ScrollReset';
import { useDispatch } from 'react-redux';
import config from "./auth_config.json";
import { Auth0Provider } from "./react-auth0-spa";
import { renderRoutes } from 'react-router-config';
import { login } from './redux/actions';
import budgetActions from 'src/redux/actions/budgetActions';
import useSettings from './hooks/useSettings';
import ReactGA from 'react-ga';

const { checkSetup } = budgetActions;

function AppWrapper({ history, mixpanel }) {

    const dispatch = useDispatch();
    const { saveSettings } = useSettings();

    const onRedirectCallback = (appState, token, user) => {
        dispatch(login(token, user));
        dispatch(checkSetup(history))
            .then(response => {
                // login complete
                if (!response) return;

                mixpanel.identify(user.sub);
                mixpanel.people.set({
                    "$email": user.email,    // only reserved properties need the $
                    "USER_ID": user.sub,    // use human-readable names
                });


                if (!response.data.setupComplete) {
                    history.push('/setup/complete');
                } else {
                    dispatch(budgetActions.loadBudget())
                        .then(response2 => {
                            saveSettings({ theme: response2.data.theme })
                        })
                    dispatch(budgetActions.getPaymentTracker());

                    history.push('/dashboard');
                }
                return response;
            })
    };

    useEffect(() => {
        ReactGA.initialize('UA-158519771-1');
        ReactGA.set({ page: window.location.pathname })
        ReactGA.pageview(window.location.pathname)
    }, []);

    history.listen(location => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
    })

    const clientId = process.env.REACT_APP_CYPRESS === 'true' ? process.env.REACT_APP_AUTH0_CLIENT : config.clientId;

    return (
        <Auth0Provider
            domain={config.domain}
            client_id={clientId}
            audience={config.audience}
            redirect_uri={`${window.location.protocol}//${window.location.host}/auth/login`}
            onRedirectCallback={onRedirectCallback}
        >
            <ScrollReset />
            <CookiesNotification />
            {renderRoutes(routes)}
        </Auth0Provider>
    );
}

export default AppWrapper;