import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
	Button,
	ButtonGroup,
	Typography,
	TableFooter,
	Container
} from '@material-ui/core';
import { FormattedNumber } from 'react-intl';
import MUIDataTable from "mui-datatables";
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => createStyles({
	table: {
		minWidth: 650,
	},
	footerCell: {
		backgroundColor: theme.palette.background.paper,
		borderBottom: 'none',
	},
}));

// @TODO move to utility
function slugify(text) {
	return text.replace(/[^-a-zA-Z0-9\s+]+/ig, '').replace(/\s+/gi, "-").toLowerCase();
}

function TableView({ budgetDataSource, classes, budgetedGrandTotal, finalGrandTotal }) {

	const history = useHistory();
	const [columns, setColumns] = React.useState([
		{
			name: "name",
			label: 'Name',
			options: {
				filter: false,
				customBodyRender: (value) => (<Typography>{value}</Typography>)
			}
		},
		{
			name: 'budgeted',
			label: 'Budgeted',
			options: {
				filter: false,
				customBodyRender: (value) => (<FormattedNumber style={`currency`} currency="USD" value={value} />)
			}
		},
		{
			name: 'actual',
			label: 'Final',
			options: {
				filter: false,
				customBodyRender: (value) => (<FormattedNumber style={`currency`} currency="USD" value={value} />)
			}
		}
	]);

	useEffect(() => {
		if (!isMobile) {
			setColumns(c => [...c, {
				name: 'slug',
				label: ' ',
				options: {
					filter: false,
					customBodyRender: (value, tableMeta) => (<ButtonGroup className={classes.button}
						color="primary"
						variant="text">
						<Button component={RouterLink}
							to={`/budget/detail/${value}`}
							data-cy={`view-btn-${tableMeta.rowIndex}`}>
							View
								</Button>
					</ButtonGroup>)
				}
			}]);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const options = {
		filter: false,
		search: false,
		print: false,
		download: false,
		viewColumns: false,
		responsive: 'standard',
		selectableRows: 'none',
		pagination: false,
		setTableProps: () => {
			return {
				size: 'small'
			}
		},
		onRowClick: (_, rowMeta) => {
			if (!isMobile) return;

			const row = budgetDataSource[rowMeta.rowIndex];
			history.push(`/budget/detail/${row.slug}`);
		},
		customTableBodyFooterRender: function (opts) {
			const budgetSum =
				budgetDataSource.reduce((accu, item) => {
					return accu + item.budgeted;
				}, 0)

			const finalSum =
				budgetDataSource.reduce((accu, item) => {
					return accu + item.actual;
				}, 0);

			return (
				<TableFooter className={classes.footerCell}>
					<TableRow>
						{opts.selectableRows !== 'none' ? <TableCell className={classes.footerCell} /> : null}
						{opts.columns.map((col, index) => {
							if (col.display === 'true') {
								if (col.name === 'budgeted') {
									return (
										<TableCell key={index} className={classes.footerCell}>
											<FormattedNumber style={`currency`} currency="USD" value={budgetSum} />
										</TableCell>
									);
								} else if (col.name === 'actual') {
									return (
										<TableCell key={index} className={classes.footerCell}>
											<FormattedNumber style={`currency`} currency="USD" value={finalSum} />
										</TableCell>
									);
								} else if (col.name === 'name') {
									return (
										<TableCell key={index} className={classes.footerCell}>
											TOTAL
										</TableCell>
									)

								} else {
									return <TableCell key={index} className={classes.footerCell} />;
								}
							}
							return null;
						})}
					</TableRow>
				</TableFooter>
			);
		},
	};

	return (<MUIDataTable
		data={budgetDataSource}
		columns={columns}
		options={options}
	/>)
}

// function ChartView({ budgetDataSource }) {

// 	return (
// 		<Grid container justify="center">
// 			<Grid item md={8} style={{ flex: 1 }}>
// 				<CategoryPieChart data={budgetDataSource} />
// 			</Grid>
// 		</Grid>

// 	)
// }

function Overview({ budgetLineItems, categoryData, view }) {
	const classes = useStyles();
	const allCategories = Object.keys(categoryData);

	const budgetDataSource = allCategories.map(c => {
		const budgetedTotal = categoryData[c]
			.map(i => i.budgeted)
			.reduce((a, b) => a + b, 0);

		const actualTotal = categoryData[c]
			.map(i => i.actual)
			.reduce((a, b) => a + b, 0);
		return {
			name: c,
			budgeted: budgetedTotal,
			actual: actualTotal,
			slug: slugify(c)
		}
	});

	const budgetedGrandTotal = budgetLineItems
		.map(i => i.budgeted)
		.reduce((a, b) => a + b, 0);

	const finalGrandTotal = budgetLineItems
		.map(i => i.actual)
		.reduce((a, b) => a + b, 0);

	return (
		<Container maxWidth="md" disableGutters={true}>
			<TableView
				budgetDataSource={budgetDataSource}
				classes={classes}
				budgetedGrandTotal={budgetedGrandTotal}
				finalGrandTotal={finalGrandTotal} />
		</Container>);

}

Overview.propTypes = {
	// onDeleteItem: PropTypes.func.isRequired
	budgetLineItems: PropTypes.array.isRequired,
	categoryData: PropTypes.object.isRequired,
	view: PropTypes.string.isRequired
};

export default Overview;