import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Card, Typography, Grid
} from '@material-ui/core';
import Label from 'src/components/Label';
import { FormattedNumber } from 'react-intl';

const useStyles = makeStyles((theme) => ({
	root: {},
	content: {
		padding: 0
	},
	item: {
		padding: theme.spacing(3),
		textAlign: 'center',
		[theme.breakpoints.up('md')]: {
			'&:not(:last-of-type)': {
				borderRight: `1px solid ${theme.palette.divider}`
			}
		},
		[theme.breakpoints.down('sm')]: {
			'&:not(:last-of-type)': {
				borderBottom: `1px solid ${theme.palette.divider}`
			}
		}
	},
	valueContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	label: {
		marginLeft: theme.spacing(1)
	}
}));

function CategoryOverview({ budgetData, className, ...rest }) {
	const classes = useStyles();

	let budgetedTotal = 0, actualTotal = 0, percentOver = 0;
	if (budgetData) {
		budgetedTotal = budgetData
			.map(i => i.budgeted)
			.reduce((a, b) => a + b, 0);

		actualTotal = budgetData
			.map(i => i.actual)
			.reduce((a, b) => a + b, 0);

		percentOver = Math.ceil(((actualTotal - budgetedTotal) / budgetedTotal) * 100);
	}

	/**
	const totalPaidPayments = budgetData
		.map(lineItem => {
			let totalPaidPaymentsAmount = 0;

			if (lineItem.payments.expenses.length > 0) {
				// console.log('using expenses', lineItem.payments)
				totalPaidPaymentsAmount = lineItem.payments.expenses
					.map(e => e.amount)
					.reduce((a, b) => a + b, 0);
			} else if (lineItem.payments.paymentSchedule.length > 0) {
				// console.log('using payment schedule', lineItem.payments);
				const paidLineItems = lineItem.payments.paymentSchedule
					.filter(ps => ps.paid)

				if (paidLineItems.length > 0) {
					totalPaidPaymentsAmount = paidLineItems
						.map(ps => ps.amount)
						.reduce((a, b) => a + b, 0);
				}

			}

			return totalPaidPaymentsAmount;
		})
		.reduce((a, b) => a + b, 0);

	const outstandingBalance = budgetedTotal - totalPaidPayments;
	 */



	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<Grid
				alignItems="center"
				container
				justify="space-between"
			>
				<Grid
					className={classes.item}
					item
					md={6}
					xs={12}
				>
					<Typography
						component="h2"
						gutterBottom
						variant="overline"
					>
						Budgeted
		  			</Typography>
					<div className={classes.valueContainer} data-cy="budgeted-container">
						<Typography variant="h3">
							<FormattedNumber style={`currency`} currency="USD" value={budgetedTotal} />
						</Typography>
					</div>
				</Grid>
				<Grid
					className={classes.item}
					item
					md={6}
					xs={12}
				>
					<Typography
						component="h2"
						gutterBottom
						variant="overline"
					>
						Final Cost
					</Typography>
					<div className={classes.valueContainer} data-cy="actual-container">
						<Typography variant="h3">
							<FormattedNumber style={`currency`} currency="USD" value={actualTotal} />
						</Typography>
						{percentOver > 0 &&
							<Label
								className={classes.label}
								color="error"
								variant="contained"
							>
								+{percentOver}%
						</Label>
						}
					</div>
				</Grid>
				{/*<Grid
					className={classes.item}
					item
					md={4}
					sm={4}
					xs={12}
				>
					<Typography
						component="h2"
						gutterBottom
						variant="overline"
					>
						Unpaid Balance
					</Typography>
					<div className={classes.valueContainer} data-cy="actual-container">
						<Typography variant="h3">
							<FormattedNumber style={`currency`} currency="USD" value={outstandingBalance} />
						</Typography>
					</div>
				</Grid>*/}
			</Grid>
		</Card>
	);
}

CategoryOverview.propTypes = {
	className: PropTypes.string,
	budgetData: PropTypes.array.isRequired
};

export default CategoryOverview;
