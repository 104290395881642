import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
	Button,
	Dialog,
	Grid,
	Paper,
	Typography,
	colors
} from '@material-ui/core';
import { StripeProvider, Elements } from 'react-stripe-elements';
import PaymentForm from './Stripe/PaymentForm';

const useStyles = makeStyles((theme) => ({
	root: {
		width: 960
	},
	header: {
		maxWidth: 600,
		margin: '0 auto',
		padding: theme.spacing(3)
	},
	content: {
		marginTop: theme.spacing(5),
		padding: theme.spacing(2),
		maxWidth: 720,
		margin: '0 auto'
	},
	product: {
		overflow: 'visible',
		position: 'relative',
		padding: theme.spacing(5, 3),
		cursor: 'pointer',
		transition: theme.transitions.create('transform', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		'&:hover': {
			transform: 'scale(1.1)'
		}
	},
	image: {
		borderRadius: theme.shape.borderRadius,
		position: 'absolute',
		top: -24,
		left: theme.spacing(3),
		height: 48,
		width: 48,
		fontSize: 24
	},
	divider: {
		margin: theme.spacing(2, 0)
	},
	options: {
		lineHeight: '26px'
	},
	recommended: {
		backgroundColor: theme.palette.primary.main,
		'& *': {
			color: `${theme.palette.common.white} !important`
		}
	},
	contact: {
		margin: theme.spacing(3, 0)
	},
	actions: {
		backgroundColor: colors.grey[100],
		padding: theme.spacing(2),
		display: 'flex',
		justifyContent: 'center'
	},
}));

function PricingModal({
	open, onClose, className, ...rest
}) {
	const classes = useStyles();
	const [stripe, setStripe] = useState(null);

	useEffect(() => {

		if (!window.Stripe) {
			const stripeJs = document.createElement('script');
			stripeJs.src = 'https://js.stripe.com/v3/';
			stripeJs.async = true;
			stripeJs.onload = () => {
				setStripe(window.Stripe('pk_test_xfInPbqexW7XzwqfD4YNk7ys00EcWwHEec'));
			};

			stripeJs.onerror = (err) => {
				this.setState({
					error: 'Something went wrong trying to load our credit card form.  Please try again later',
				});
			};

			document.body.appendChild(stripeJs);

		} else {
			setStripe(window.Stripe('pk_test_xfInPbqexW7XzwqfD4YNk7ys00EcWwHEec'));
		}


		// apiKey="pk_test_xfInPbqexW7XzwqfD4YNk7ys00EcWwHEec"
	}, [setStripe])

	return (
		<Dialog
			maxWidth="lg"
			onClose={onClose}
			open={open}
			disableEnforceFocus
		>
			<div
				{...rest}
				className={clsx(classes.root, className)}
			>
				<div className={classes.header}>
					<Typography
						align="center"
						gutterBottom
						variant="h3"
					>
						Upgrade your account for unlimited access!
          </Typography>
					<Typography
						align="center"
						className={classes.subtitle}
						variant="subtitle2"
					>
						(descriptive sales messaging here)
          </Typography>
				</div>
				<div className={classes.content}>
					<Grid
						container
						justify="center"
					>
						<Grid
							item
							md={8}
							xs={12}
						>
							<Paper
								className={classes.product}
								elevation={1}
							>
								<StripeProvider
									stripe={stripe}
								>
									<Elements>
										<PaymentForm />
									</Elements>
								</StripeProvider>
							</Paper>
						</Grid>
					</Grid>
				</div>
				<div className={classes.actions}>
					<Button
						className={classes.startButton}
						variant="text"
						onClick={onClose}
					>
						Cancel
          </Button>
				</div>
			</div>
		</Dialog>
	);
}

PricingModal.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	open: PropTypes.bool
};

export default PricingModal;
