import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';

function LineItemActionMenu(
    {
        onDelete,
        onNotes
    }
) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="more options"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                data-cy="line-item-action-menu"
                title="Options"
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => { onNotes(); handleClose(); }} data-cy="line-item-action-menu-notes">Notes</MenuItem>
                <MenuItem onClick={() => { onDelete(); handleClose(); }}
                    data-cy="line-item-action-menu-delete">Delete</MenuItem>
            </Menu>
        </div>
    );
}

LineItemActionMenu.propTypes = {
    // row: PropTypes.object.isRequired,
    onNotes: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
}


export default LineItemActionMenu;