import React from 'react';
import { Router } from 'react-router-dom';

import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider, createStyles } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { configureStore } from './redux/store';
import StylesProvider from './components/StylesProvider';
import { IntlProvider } from 'react-intl';
import { PersistGate } from 'redux-persist/integration/react';
import { createTheme } from 'src/theme';
import useSettings from 'src/hooks/useSettings';
import mixpanel from 'mixpanel-browser';
import { MixpanelProvider, MixpanelConsumer } from 'react-mixpanel';

import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
// import './mock';
import './assets/scss/main.scss';

import AppWrapper from './AppWrapper';
import { makeStyles } from '@material-ui/core';

mixpanel.init("fc049d9de2ae88074bde46331bf03739");

const history = createBrowserHistory();
const store = configureStore();

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      height: '100%',
      width: '100%'
    },
    '#root': {
      height: '100%',
      width: '100%'
    }
  }
}));

function App() {
  useStyles();

  const { settings } = useSettings();

  return (
    <MixpanelProvider mixpanel={mixpanel}>
      <StoreProvider store={store}>
        <PersistGate persistor={store.__PERSISTOR} loading={null}>
          <IntlProvider
            locale="en"
          >
            <ThemeProvider theme={createTheme(settings)}>
              <StylesProvider direction={'ltr'}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <SnackbarProvider maxSnack={1}>
                    <Router history={history}>
                      <MixpanelConsumer>
                        {mixpanel => <AppWrapper history={history} mixpanel={mixpanel} />}
                      </MixpanelConsumer>
                    </Router>
                  </SnackbarProvider>
                </MuiPickersUtilsProvider>
              </StylesProvider>
            </ThemeProvider>
          </IntlProvider>
        </PersistGate>
      </StoreProvider>
    </MixpanelProvider>
  );
}

export default App;
