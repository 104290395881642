/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PaymentIcon from '@material-ui/icons/Payment';

export default [
  {
    items: [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: DashboardIcon
      },
      {
        title: 'Budget',
        href: '/budget',
        icon: AccountBalanceWalletIcon
      },
      {
        title: 'Payments',
        href: '/payments',
        items: [
          {
            title: 'Tracker',
            href: '/payments/tracker'
          },
          {
            title: 'History',
            href: '/payments/history'
          },

        ],
        icon: PaymentIcon
      },
      {
        title: 'Settings',
        href: '/settings',
        icon: SettingsIcon,
        items: [
          {
            title: 'General',
            href: '/settings/general'
          },
          {
            title: 'Security',
            href: '/settings/security'
          },
          {
            title: 'Budget',
            href: '/settings/budget'
          }
        ]
      },
    ]
  },
];
